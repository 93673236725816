@media (min-width: 769px) {
  .best-execution-section {
    padding: 10% 5%;
    background: rgb(244, 244, 244);
  }

  .best-execution-section > *,
  .best-execution-section a {
    font-family: "Circular Std Book";
  }

  .best-execution-header {
    font-size: 200%;
    font-family: var(--heading-text) !important;
  }

  strong {
    font-size: 1rem;
    font-family: var(--heading-text) !important;
    text-decoration: underline !important;
  }

  .best-execution-section > h2 {
    text-decoration: underline;
  }

  .best-execution-section > ul {
    padding-left: 1.5rem;
  }
  .best-execution-section > ul > li {
    font-family: "Circular Std Book";
  }
}
