@media (max-width: 768px) {
  .throg-header {
    position: fixed;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    z-index: 99;
    transition: all 0.2s ease;
    /* z-index: 305; */
    padding-left: 1rem;
    /* background-color: rgb(238, 238, 238);
    box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.123); */
  }

  .header-link-container {
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    gap: 3rem;
  }

  .login-link {
    font-weight: 600;
    text-decoration: none;
    padding-bottom: 1%;
    font-size: 150%;
    background: none;
    border: none;
    /* font-family: "Playfair Display", serif; */

    text-transform: uppercase;
    letter-spacing: 2px;

    color: #c2a044;
  }

  .header-link {
    /* margin-right: 1.5vw; */
    font-weight: 400;
    text-decoration: none;
    background: none;
    border: none;
    /* font-family: "Playfair Display", serif; */
    font-family: var(--primary-text);
    font-size: 1.5rem;
    outline: none;
    border-bottom: 1px solid transparent;
    cursor: pointer;

    /* text-transform: uppercase; */
    /* letter-spacing: 2px; */

    color: #c2a044;

    transition: all 0.2s ease-in;
    color: rgba(245, 245, 245, 0.89);
  }

  .header-link::after {
    content: "";
    position: absolute;
    width: 0%;
    height: 1px;
    background-color: gold;
    left: 0;
    bottom: 0;
  }

  /* .header-link::after:hover {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: gold;
    left: 50%;
    bottom: 0;
  } */

  .header-link:hover {
    /* border-bottom: 1px solid gold; */

    color: gold;
  }

  .header-link:hover::after {
    animation: extend 0.2s linear;
    animation-fill-mode: both;
  }

  .throg-logo-header {
    height: 4rem !important;
    width: calc(4rem * 3.69) !important;
  }

  .services-link-container {
    position: relative;
  }

  .services-links-list {
    list-style: none;

    padding: 5% 0%;
    transition: all 0.2s ease;
    margin-top: -1%;
  }
  .services-links-list > li {
    padding: 5%;
  }

  .header-link-container-mob {
    position: fixed;

    gap: 1rem;

    height: 100vh !important;

    background: rgb(249, 247, 247);

    width: 100%;
    padding: 5% 5%;

    overflow-y: auto;
    left: -100%;
    transition: all 0.2s ease;
  }

  .header-link-container-mob > div {
    margin: 5% 0;
  }

  .open-btn {
    padding: 0%;
    background: none;
    font-size: 28px;
    border: none;
    padding-left: 1rem;

    align-self: center;
    justify-self: center;
    margin-right: 5vw;
    margin-bottom: 2vh;
    color: rgb(18, 18, 65);
  }

  .close-btn {
    position: absolute;
    right: 1rem;
    padding: 0.25rem;
    font-size: 200%;
    border: none;
    background: none;
    z-index: 999;
    color: rgb(18, 18, 65);
  }
}
