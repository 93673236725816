@media (min-width: 768px) {
  .cookies-notification {
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    background: whitesmoke;
    text-align: center;

    border-top-right-radius: 1rem;
    border-top-left-radius: 1rem;
    z-index: 999;
    /* border-bottom-right-radius: 1rem; */
  }

  .cookies-notification > button {
    /* margin: 5%; */
    padding: 0.5rem 2rem;
    margin-bottom: 1rem;
    background: rgb(0, 145, 255);
    border: none;
    color: whitesmoke;
    cursor: pointer;
  }
}
@media (max-width: 768px) {
  .cookies-notification {
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    background: whitesmoke;
    border-top-right-radius: 1rem;
    border-top-left-radius: 1rem;
    text-align: center;
    z-index: 9999;
  }

  .cookies-notification > button {
    margin-bottom: 5%;
    padding: 2.5% 5%;

    background: rgb(0, 145, 255);
    border: none;
    color: whitesmoke;
    cursor: pointer;
  }
}
