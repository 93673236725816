@media (min-width: 769px) {
  .throg-header {
    position: fixed;
    min-height: 10%;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    padding: 0;
    z-index: 99;
    transition: all 0.2s ease;
    /* max-height: 10vh; */
    /* background-color: whitesmoke; */
    /* padding-bottom: 0.5rem; */
  }

  .throg-logo-header {
    height: 5rem;
    width: calc(5rem * 3.69);
    margin-left: 1.5rem;
  }

  .header-link-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-items: flex-end;
  }

  .login-link {
    margin-right: 5vw;

    text-decoration: none;

    cursor: pointer;
    padding-bottom: 1%;
    font-weight: 400;

    text-transform: uppercase;
    letter-spacing: 2px;

    color: rgb(29, 93, 114);
    color: #c2a044;
  }

  .login-link:hover {
    border-bottom: 1px solid gold;
    color: #7b6320;
    color: gold;
  }

  .header-link {
    /* margin-right: 1.5vw; */
    font-weight: 400;
    text-decoration: none;
    background: none;
    border: none;
    /* font-family: "Playfair Display", serif; */
    font-size: 1.5rem;
    outline: none;
    border-bottom: 1px solid transparent;
    cursor: pointer;

    /* text-transform: uppercase; */
    /* letter-spacing: 2px; */

    color: #c2a044;

    transition: all 0.2s ease-in;
    color: rgba(245, 245, 245, 0.89);
  }

  .header-link::after {
    content: "";
    position: absolute;
    width: 0%;
    height: 1px;
    background-color: gold;
    left: 0;
    bottom: 0;
  }

  /* .header-link::after:hover {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: gold;
    left: 50%;
    bottom: 0;
  } */

  .header-link:hover {
    /* border-bottom: 1px solid gold; */

    color: gold;
  }

  .header-link:hover::after {
    animation: extend 0.2s linear;
    animation-fill-mode: both;
  }

  .home-link {
    color: rgb(17, 65, 129) !important;
  }

  .services-link-container {
    position: relative;
  }

  .services-links-list {
    position: absolute;
    list-style: none;

    width: max-content;
    min-width: 250%;
    padding: 2rem 0;
    transition: all 0.2s ease;
    margin-top: 0%;
  }
  .services-links-list > li {
    padding: 1rem;

    background: rgb(249, 247, 247);

    color: rgb(17, 63, 142);
  }

  @supports (-ms-ime-align: auto) {
    .throg-header {
      height: 95px;
    }
  }
}

@media (min-width: 1600px) {
  /* .throg-logo-header {
    height: 88px;
    width: 324px;
    margin-left: 1.5rem;
  } */
}

@keyframes extend {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}
