@media (min-width: 769px) {
  .privacy-section {
    padding: 10% 5%;
    background: rgb(244, 244, 244);
  }

  .privacy-header {
    font-family: var(--heading-text) !important;
  }

  .privacy-section strong {
    font-family: var(--primary-text) !important;
  }

  .privacy-header {
    font-size: 2rem;
  }

  .privacy-section h2 {
    font-size: 1rem;
    text-decoration: underline;
    font-family: var(--heading-text) !important;
  }

  .privacy-section > ul {
    padding-left: 1.5rem;
  }
  .privacy-section > ul > li {
    /* font-family: "Circular Std Book", sans-serif; */
  }

  address {
    font-style: normal;
  }
}
