@media (max-width: 768px) {
  .downloads-section {
    /* border-bottom: 1px solid #c2a044; */
  }

  .downloads-bg-img {
    background: url("https://images.unsplash.com/photo-1572549113071-c90a8f3c26a5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2000&q=80");
    background: url("https://storage.googleapis.com/throgmortoncm-files/main-images/downloads-min.jpg");
    height: 30vh;
    width: 100%;

    background-size: 150%;
    background-position-y: 40%;
    background-position-x: 0% !important;

    padding: 25% 5%;
    padding: 0 5%;
    display: grid;
    align-items: end;

    background-repeat: no-repeat;
  }

  .downloads-content {
    min-height: 50vh;
    padding: 5%;
    border-top: 1px solid #c2a044;
  }

  .downloads-category {
    padding: 5% 5% 5% 5%;
  }

  .downloads-btn {
    font-size: 150%;
    background: none;
    border: none;
    color: gold;
    width: 100%;
    text-align: left;
    border-bottom: 1px solid lightgray;
    cursor: pointer;
    outline: none;
    font-family: "Circular Std Book";
    color: #c2a044;
  }

  .download-links {
    transition: all 0.2s ease;

    overflow: hidden;
    display: flex;
    flex-direction: column;

    background: none;
    justify-content: center;
  }

  .date-header {
    color: lightgray;
    padding-bottom: 1rem;
    padding: 0;
    margin: 0;
    margin-top: 1rem;
    border-bottom: 1px solid gold;
    width: fit-content;
    font-size: 1rem;
    font-family: var(--heading-text);
  }

  .download-link {
    color: whitesmoke;

    padding: 1rem 1rem;
  }

  .expand-btn {
    float: right;
  }

  .sign-up-text {
    font-size: 100% !important;
    color: whitesmoke;
  }
  .Collapsible {
    margin: 0rem 0 !important;
    padding-bottom: 2rem;
  }

  .downloads-col {
    padding: 2rem 2rem;
  }

  /* iframe {
    height: 90vh;
  } */

  .pdfviewer-container {
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0rem;
    left: 0;
    z-index: 9999;
    padding: 0rem 0rem;
    padding-top: 2rem;
    background-color: black;
  }

  iframe.pdfviewer {
    height: 100%;
    width: 100%;
  }

  svg.close {
    /* size: 3rem; */
    float: right;
    height: 2rem;
    width: 2rem;
    cursor: pointer;
    margin-right: 1rem;
  }

  svg.close > path {
    fill: var(--primary-blue);
    stroke: var(--primary-blue);
  }

  .subscribe-input {
    padding: 1rem;
    font-size: 1rem;
    border: none;
    /* border-radius: 0.25rem 0.25rem 0 0; */
    border-radius: 0;
    border-bottom: 3px solid #c2a044;
    background: rgba(92, 102, 142, 0.694) !important;
    transition: all 0.2s ease;
    outline: none !important;
    color: whitesmoke;
  }

  .subscribe-input:valid {
    background: #c2a044 !important;
    border-bottom-color: #164382;
  }

  .subscribe-input::placeholder {
    color: white;
  }

  .subscribe-input:focus {
    background: #c2a044 !important;
    border-bottom-color: #164382;
  }

  input:-internal-autofill-selected {
    background: red !important;
  }

  .subscribe-btn {
    padding: 1rem;
    font-size: 1rem;
    border-radius: 0.25rem;
    background: #164382;
    border: none;
    transition: all 0.2s ease;
    outline: none !important;
    color: whitesmoke;
    cursor: pointer;
  }

  .subscribe-btn:active {
    background: #c2a044;
    color: whitesmoke;
  }

  .subscribe-btn:disabled {
    background: #1643826f;
    cursor: not-allowed;
    color: rgb(240, 235, 235);
  }

  .subscribe-status-container {
    padding: 1rem;
    border-radius: 0.25rem;
  }

  .error {
    background: rgb(227, 66, 66);
    color: whitesmoke;
    grid-column: 1/3;
  }

  .success {
    background: rgb(80, 213, 106);
    color: whitesmoke;
    grid-column: 1/3;
  }

  .close-subscribe-modal-btn {
    position: absolute;
    right: 0rem;
    top: 0rem;
    padding: 0.25rem 0.5rem;
    border: none;
    font-size: 1rem;
    background: none;
    cursor: pointer;
  }

  .subscribe-modal-btn {
    padding: 0.5rem;
    /* font-family: "Circular Std Book", sans-serif; */
    width: min-content;
    font-size: 1rem;
    background: #164382;
    color: #c2a044;
    border: none;
    border-radius: 0.25rem;
    cursor: pointer;
    max-width: 100px;
    background: white;
  }

  .subscribe-header {
    font-size: 150%;

    font-weight: 400;
    color: #164382;
    /* font-family: "Circular Std Book", sans-serif; */
    /* color: whitesmoke; */
    margin-top: 0;
    color: #c2a044;
    padding: 1rem;
    margin-bottom: 0;
  }

  .subscribe-form-container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
    padding: 1rem;
  }
}
