.loading-view {
  text-align: center;
  padding: 1rem;
  height: 100vh;
  width: 100%;
  background: #164382;
  background: whitesmoke;
  margin: 0;
}

.loading-view > img {
  width: 325px;
  height: 88px;
}

.loader,
.loader:after {
  border-radius: 50%;
  width: 5em;
  height: 5em;
}
.loader {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 0.5em solid #c2a044;
  border-right: 0.5em solid #c2a044;
  border-bottom: 0.5em solid #c2a044;
  border-left: 0.5em solid #164382;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media (max-width: 768px) {
}
@media (min-width: 769px) {
  .App {
    height: 100vh;
  }
}
