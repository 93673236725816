@media (min-width: 769px) {
  .regulatory-section {
    padding: 10% 5%;
    background: rgb(244, 244, 244);
  }

  .regulatory-section > h2 {
    font-size: 1rem !important;
    font-family: var(--heading-text) !important;
    text-decoration: underline;
  }

  strong {
    font-size: 1rem !important;
    font-family: var(--heading-text) !important;
    text-decoration: underline !important;
  }

  .regulatory-header {
    font-size: 200%;
    font-family: var(--heading-text) !important;
  }

  .regulatory-section > ul {
    padding-left: 1.5rem;
  }

  .regulatory-section > ul > li {
    /* font-family: "Circular Std Book"; */
  }

  table {
    width: 60%;
  }

  td {
    border-bottom: 1px solid lightgray;
  }
}
