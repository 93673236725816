@media (max-width: 768px) {
  .regulatory-section {
    padding: 15% 5%;
    background: rgb(244, 244, 244);
  }

  .regulatory-section > h2,
  h3 {
    font-family: var(--heading-text) !important;
    font-size: 1rem;
  }

  .regulatory-header {
    font-size: 200%;
    font-family: var(--heading-text) !important;
  }

  .regulatory-section > ul {
    padding-left: 1.5rem;
  }

  .regulatory-section > ul > li {
  }

  table {
    width: 100%;
  }

  td {
    border-bottom: 1px solid lightgray;
  }
}
