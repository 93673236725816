@media (min-width: 769px) {
  .team-main-cont {
    display: grid;
    grid-template-columns: 1fr 3fr;
    /* padding: 0 10%; */
    /* margin-left: 1rem; */
    align-items: center;
    /* justify-items: center; */
    /* height: 375px; */
    padding: 1rem 0;
    /* border-left: 1px solid lightslategray; */
  }

  .team-user-select-cont {
    display: grid;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    max-height: 60vh;
    overflow-y: auto;
    padding-right: 2rem;

    &::-webkit-scrollbar {
      width: 0.25rem;
      display: block;

    }

    &::-webkit-scrollbar-thumb {
      background-color:  rgba(138, 138, 138, 0.638);;
      outline: none;
      border-radius: 1rem;
    }

    & div {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 1rem;
    }
  }

  .team-select-btn {
    border: none;
    cursor: pointer;
    background-color: transparent;
    outline: none;
    position: relative;
    text-align: left;
    font-size: 1.1rem;
    color: whitesmoke;
    /* margin-left: 1rem;
    padding-left: 2rem;
    width: 150px; */
  }

  .team-select-btn::after {
    content: "";
    height: 1px;
    width: 0%;

    position: absolute;

    border-bottom: 1px solid goldenrod;
    left: 0%;
    bottom: 0;
  }

  .team-select-btn:hover::after {
    animation: extend 0.2s linear;
    animation-fill-mode: both;
  }

  @keyframes extend {
    from {
      width: 0%;
    }
    to {
      width: 100%;
    }
  }

  .our-team-article {
    color: whitesmoke;
    margin: 0rem 2.5rem;
    padding-left: 1.25rem;
    border-left: 1px solid white;
  }

  .our-team-article > h1 {
    margin-top: 0;
  }

  .team-bg-img {
    height: 100%;
    width: 100%;
    position: relative;

    background-size: cover;
    background-position-y: 0%;
    background-position-x: 50%;
    /* padding: 15%; */

    background-repeat: no-repeat;
  }

  .team-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    /* max-height: 50vh;s */
    overflow: auto;
    row-gap: 1rem;
    max-height: 650px;
  }

  .team-grid::-webkit-scrollbar {
    width: 5px;
    display: none;
  }

  /* Track */
  .team-grid::-webkit-scrollbar-track {
    background: #f1f1f1db;
  }

  /* Handle */
  .team-grid::-webkit-scrollbar-thumb {
    background: #c2a044;
  }

  /* Handle on hover */
  .team-grid::-webkit-scrollbar-thumb:hover {
    background: #ae8410;
  }

  .team-card {
    display: grid;
    grid-template-columns: 1fr;
    width: fit-content;
    /* justify-items: center; */
    cursor: pointer;
  }

  .team-card-img {
    height: 225px;
    width: 225px;
    background-color: white;
    object-fit: contain;
  }

  .team-card-details {
    padding: 0.5rem 0;

    display: grid;
    max-height: 266px;
  }

  .team-card-details-modal {
    padding: 0.5rem;

    display: grid;
  }

  .team-member-title-modal-mob {
    display: none;
  }

  .team-card-name {
    font-size: 1.25rem;
    color: whitesmoke;
    padding-bottom: 0.5rem;

    margin: 0;

    font-weight: lighter;
  }

  .team-card-role {
    font-size: 1rem;
    margin: 0;
    color: rgb(210, 210, 210);
    color: rgb(138, 138, 138);
    padding: 0.5rem 0;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: lighter;
  }

  .team-member-modal {
    display: grid;
    grid-template-columns: auto 1fr;
  }

  .team-bio {
    color: rgb(145, 144, 144);
  }

  .modal-navigation-container {
    justify-self: end;
  }

  .modal-navigation-container > button {
    font-size: 1rem;
    color: #c2a044;
    background: none;
    border: none;
    padding: 0 1rem;

    cursor: pointer;
  }

  .close-modal-btn {
    position: absolute;
    right: 0rem;
    padding: 0.25rem 0.5rem;
    border: none;
    font-size: 1rem;
    background: none;
    cursor: pointer;
  }

  .ReactModal__Overlay--after-open {
    background-color: rgba(0, 0, 0, 0.687) !important;
  }

  .bio-links-cont {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .bio-link {
    color: #c2a044;
    /* color: rgb(17, 65, 129); */
    margin-right: 1rem;
  }

  .bio-link:hover {
    color: goldenrod;
  }

  .bio-link > img {
    height: 1.25rem;
    width: 1.25rem;
    vertical-align: middle;
  }

  .active-team {
    transition: all 0.2s ease-in;
    color: var(--primary-gold);
  }

  .team-img {
    /* object-fit: cover; */
    width: 250px;
    height: 300px;
    /* filter: brightness(0.7); */
  }

  .team-col-1 {
    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: center;
  }

  .team-links-cont {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .team-email-link {
    text-decoration: none;
    color: var(--primary-gold);
    font-size: 1.25rem;
  }
}

@media (min-width: 1600px) {

  .team-select-btn {
    font-size: 1rem;
    /* margin-left: 1rem;
    padding-left: 2rem;
    width: 150px; */
  }
}
