@media (max-width: 768px) {
  .careers-section {
  }

  .careers-bg-img {
    background: url("https://storage.googleapis.com/throgmortoncm-files/main-images/careers-min.jpg");
    height: 30vh;
    width: 100%;

    background-size: cover;
    background-position-y: 25%;
    padding: 0 5%;
    display: grid;
    align-items: end;

    background-repeat: no-repeat;
  }

  .careers-article {
    color: whitesmoke;
    font-size: 125%;

    padding: 5%;

    height: max-content;
    border-top: 1px solid #c2a044;
  }
  .recruitment-link {
    color: #c2a044 !important;
  }
}
