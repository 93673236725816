@media (max-width: 768px) {
  #contact {
    align-items: center;
    /* border-bottom: 1px solid #c2a044; */
  }

  .contact-content-container {
    display: grid;
    gap: 2rem;
  }

  .contact-bg-img {
    padding: 25% 5%;

    background: url("https://storage.googleapis.com/throgmortoncm-files/main-images/contact-min.jpg");
    height: 30vh;
    width: 100%;

    background-size: cover;
    background-position-y: 30%;
    padding: 0 5%;
    display: grid;
    align-items: end;

    background-repeat: no-repeat;
  }

  .contact-links-container {
    display: grid;
    padding: 10% 5%;
    border-top: 1px solid #c2a044;
  }

  .contact-link {
    color: whitesmoke !important;
    font-size: 105%;
    text-decoration: none;
    padding: 5% 0 0 0;
    font-family: var(--primary-text), serif;
    text-decoration: underline;
  }

  .contact-link:hover {
    text-decoration: underline;
  }

  .contact-links {
    /* margin-top: 1rem; */
    grid-row: 1/2;
    display: grid;
    gap: 1rem;
    padding: 0 2rem;
  }

  .address-text {
    font-size: 105%;
    color: white;
    margin: 0;
    /* margin-left: calc(2.5% + 1.05rem); */
    padding: 0;
  }

  /* .address-text:nth-of-type(1) {
    margin-left: 0;
    margin-top: 5%;
  } */

  iframe.map {
    width: calc(100vw - 4rem);
    /* height: 50vh; */
    height: calc(100vw - 8rem);
    margin: 0 2rem;
    box-sizing: border-box;
  }

  .contact-links .contact-form-label {
    margin-bottom: 1rem;
    text-transform: uppercase;
  }

  .contact-form-label {
    letter-spacing: 3px;
  }

  .contact-label__locations {
    color: lightgray !important;
    display: block;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 1rem;
  }

  #map {
    width: 100%;
    height: 300px;
  }

  .map-wrapper {
    padding: 0 2rem;
  }

  .locations-container {
    padding: 0 1rem;
  }
}
