@media (min-width: 769px) {
  .services-section {
  }

  .services-bg-img {
    height: 100%;
    width: 100%;

    background-size: cover;
    background-position-y: 50%;
    padding: 15%;

    background-repeat: no-repeat;
  }

  .im-bg-img {
    background-size: cover;
    background-position: center;
    padding: 15%;
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
  }

  .services-article {
    color: whitesmoke;
    font-size: 125%;

    padding: 0% 10%;

    height: max-content;
  }

  .page-sub-list {
    padding-left: 1rem;
    color: whitesmoke;
    line-height: 1.5;
  }

  h5 {
    font-family: var(--primary-text);
    color: white;
    font-size: 1rem;
  }

  .fp-img {
    object-fit: cover;
  }
}

@media (min-width: 1100px) {
  .fp-col-2 {
    width: 82%;
    margin: 0 auto;
  }
}

@media (min-width: 1600px) {
  .image-card-img {
    height: 200px;
  }

  .fp-image-card-grid {
    grid-template-columns: 1fr;
  }
}
