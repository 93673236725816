@media (max-width: 768px) {
  .services-section {
  }

  .page-sub-list {
    padding: 0 3rem;
    color: whitesmoke;
    line-height: 1.5;
  }

  h5 {
    font-family: var(--primary-text);
    color: white;
    font-size: 1rem;
    padding: 0 2rem;
  }

  .image-card-grid {
    display: grid;
    padding: 1rem;
    margin-top: 1rem;
    gap: 2rem;
  }
}
