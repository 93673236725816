@media (min-width: 769px) {
  #contact {
  }

  .contact-container {
    padding: 15% 5%;
    padding: 15% 10%;
    gap: 1rem;
  }

  .contact-link {
    color: whitesmoke !important;
    font-size: 100%;
    text-decoration: none;
    padding: 2.5% 0;
    text-decoration: underline;
  }

  .contact-link:hover {
    color: var(--primary-gold);
  }

  .address-text {
    color: white;
    margin: 0;

    padding: 0;
  }

  .address-text:nth-of-type(1) {
    margin-left: 0;
  }

  iframe.map {
    width: 100%;
    height: 100%;
  }

  .contact-content-container {
    width: 100%;
    display: grid;
    grid-template-columns: 60% 1fr;
    gap: 3rem;
    align-items: top;
    row-gap: 1rem;
  }

  .contact-links {
    grid-column: 1/3;
    display: grid;
    gap: 1rem;
    align-content: center;
    grid-template-columns: 1fr 1fr;
  }
  .contact-links .contact-form-label {
    margin-bottom: 1rem;
    text-transform: uppercase;
  }

  .contact-form-label {
    letter-spacing: 3px;
  }

  .contact-label__locations {
    color: lightgray !important;
    display: block;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 1rem;
  }

  .linkedin-link-contact {
    margin-top: 1rem !important;
  }

  #map {
    width: 100%;
    height: auto;
  }
}

.contact-link:hover {
  color: var(--primary-gold) !important;
}

.contact-title-label {
  color: lightgray;
  text-transform: uppercase;

  font-size: 1.25rem;
}

.location-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.location-city {
  color: lightgray;
  text-decoration: underline;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin: 0;
  font-weight: normal;
}

@media (min-width: 768px) {
  .locations-container {
   grid-column: 1/3;
  }
}
