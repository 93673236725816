#icon {
  outline: none !important;
  width: 100px;
  height: 100px;
  cursor: pointer;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

@media (max-width: 768px) {
  .menu-icon {
    position: fixed;
    right: 0;
    z-index: 306;
    width: 75px;
    height: 50px;
    /* padding: 0.25rem; */
  }

  path.menu-icon-path {
    fill: none;
    -webkit-transition: stroke-dashoffset 0.4s
        cubic-bezier(0.25, -0.25, 0.75, 1.25),
      stroke-dasharray 0.4s cubic-bezier(0.25, -0.25, 0.75, 1.25);
    -moz-transition: stroke-dashoffset 0.4s
        cubic-bezier(0.25, -0.25, 0.75, 1.25),
      stroke-dasharray 0.3s cubic-bezier(0.25, -0.25, 0.75, 1.25);
    -o-transition: stroke-dashoffset 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25),
      stroke-dasharray 0.4s cubic-bezier(0.25, -0.25, 0.75, 1.25);
    -ms-transition: stroke-dashoffset 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25),
      stroke-dasharray 0.4s cubic-bezier(0.25, -0.25, 0.75, 1.25);
    transition: stroke-dashoffset 0.4s cubic-bezier(0.25, -0.25, 0.75, 1.25),
      stroke-dasharray 0.4s cubic-bezier(0.25, -0.25, 0.75, 1.25);
    stroke-width: 20px;
    stroke-linecap: round;
    stroke: goldenrod;
    stroke-dashoffset: 0px;
  }
}

@media (min-width: 769px) {
  #icon {
    width: 6rem;
    height: 6rem;
  }

  .menu-icon {
    position: fixed;
    right: 0;
    z-index: 201;
  }

  path.menu-icon-path {
    fill: none;
    -webkit-transition: stroke-dashoffset 0.5s
        cubic-bezier(0.25, -0.25, 0.75, 1.25),
      stroke-dasharray 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25);
    -moz-transition: stroke-dashoffset 0.5s
        cubic-bezier(0.25, -0.25, 0.75, 1.25),
      stroke-dasharray 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25);
    -o-transition: stroke-dashoffset 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25),
      stroke-dasharray 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25);
    -ms-transition: stroke-dashoffset 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25),
      stroke-dasharray 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25);
    transition: stroke-dashoffset 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25),
      stroke-dasharray 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25);
    stroke-width: 20px;
    stroke-linecap: round;
    stroke: goldenrod;
    stroke-dashoffset: 0px;
  }
}

path#top,
path#bottom {
  stroke-dasharray: 240px 950px;
}
path#middle {
  stroke-dasharray: 240px 240px;
  /* display: none; */
}

.cross path {
  stroke: golden;
}

.cross path#top,
.cross path#bottom {
  stroke-dashoffset: -650px;
  stroke-dashoffset: -650px;
}
.cross path#middle {
  /* stroke-dashoffset: -115px;
    stroke-dasharray: 1px 220px; */
  display: none;
}
