@media (min-width: 769px) {
  #about {
  }
}

.about-text > a {
  color: #c2a044;
  /* font-family: "Circular Std Book", sans-serif; */
}

.about-text > a:hover {
  color: gold;
}
