@keyframes bounceUpDown {
  from {
    transform: translateY(0px);
  }

  to {
    transform: translateY(-1rem);
  }
}

@keyframes bounceDownUp {
  from {
    transform: translateY(0px);
  }

  to {
    transform: translateY(1rem);
  }
}

@keyframes bounceLeftRight {
  from {
    transform: translateX(0px);
  }

  to {
    transform: translateX(-1rem);
  }
}
@keyframes bounceRightLeft {
  from {
    transform: translateX(0px);
  }

  to {
    transform: translateX(1rem);
  }
}

.custom-animation {
  animation-name: slideThenFadeIn;
  animation-duration: 5s;
  animation-timing-function: linear;
  /* animation-fill-mode: forwards; */
}

@keyframes slideThenFadeIn {
  0% {
    transform: translateX(-100vw);
    opacity: 0;
  }

  37% {
    transform: translateX(0vw);
    /* opacity: 0.5; */
  }

  75% {
    opacity: 1;
  }

  100% {
  }
}
