@media (min-width: 769px) {
  .hero-section {
    width: 100%;
    /* padding: 5% 2.5%; */
    height: 100vh;
    padding-top: 0%;
    overflow: hidden;

    display: grid;
    background-repeat: no-repeat;
    justify-items: right;
    overflow: auto;

    will-change: background-image;
    /* background: url("../media/home-min.jpg"); */
    background: url("https://storage.googleapis.com/throgmortoncm-files/main-images/home-img-min.jpg");

    /* display: grid; */
    background-size: cover;
    background-position: center;
    /* align-items: center; */
    background-repeat: no-repeat;
    /* background-position: -300px -00px;
    background-size: 150%; */
    scroll-snap-align: start;
    position: relative;
  }

  .hero-content-container {
    display: grid;
    /* grid-template-columns: 1fr 1fr; */
    grid-template-columns: 1fr;
    align-items: center !important;
    justify-items: right;
    background-color: rgba(0, 0, 0, 0.151);
    align-content: center;
    /* padding: 0 1rem; */

    width: 100%;
    height: 100%;
  }

  .hero-text-container {
    width: 40%;
    margin-bottom: 1rem;

    background-color: rgba(17, 66, 129, 0.612);

    padding: 0 2rem;
  }

  .hero-span {
    font-weight: 400;
  }

  .hero-sub-header {
    font-size: 1rem;
    text-align: left;
    font-weight: 400 !important;
    color: #164382;
    color: rgb(17, 65, 129);
    color: white;
  }

  @supports (-ms-ime-align: auto) {
    .hero-content-container {
      padding: 5%;
      width: 100%;
    }
  }

  .welcome-span {
    display: block;
    color: lightgray;
    margin-bottom: 0.5rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 1rem;
    /* font-weight: bold; */
  }

  .hero-title {
    /* font-weight: bold; */
    text-transform: none;
    font-size: 1.5rem;
    letter-spacing: 0px;
    /* text-transform: uppercase; */
    font-family: var(--heading-text) !important;
    line-height: 1.5;
    color: whitesmoke;
    display: block;
    padding-bottom: 1rem;
  }
}

@media (min-width: 1600px) {
  .hero-title {
    font-size: 1.5rem;
  }

  .hero-sub-header {
    font-size: 1.25rem;
  }
}

@media (min-width: 1300px) and (max-width: 1600px) {
  .hero-sub-header {
    padding-right: 1rem;
  }
}
