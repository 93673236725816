@media (max-width: 768px) {
  .our-team-article {
    color: whitesmoke;

    padding: 5% 2rem;

    /* height: max-content; */
    /* border-top: 1px solid #c2a044; */
    overflow: hidden;
  }

  .team-bg-img {
    background: url("https://storage.googleapis.com/throgmortoncm-files/main-images/our-team-min.jpg");
    height: 30vh;
    width: 100%;

    background-size: cover;
    background-position-y: 25%;
    padding: 0 5%;
    display: grid;
    align-items: end;

    background-repeat: no-repeat;
  }

  .team-grid {
    display: grid;
    grid-template-columns: 1fr;

    overflow: auto;
    row-gap: 1rem;
    overflow: hidden;
  }

  .team-card {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;

    cursor: pointer;
    column-gap: 1rem;
  }

  .team-card-details {
    padding: 0.5rem;

    display: grid;
  }

  .team-card-details-modal {
    padding: 0.5rem;

    display: grid;
    padding: 1rem;
    grid-column: 1/3;
  }

  .team-card-img {
    height: 150px;
    width: 150px;
    background-color: white;
  }
  .team-img-alt {
    height: 150px;
    width: 150px;
  }

  .team-card-name {
    font-size: 1.25rem;
    color: whitesmoke;

    margin: 0;
    padding: 0.5rem 0;
    /* color: var(--primary-gold); */
    font-weight: lighter;
  }

  .team-card-role {
    font-size: 75%;
    margin: 0;
    color: rgb(210, 210, 210);
    color: rgb(154, 154, 154);
    padding: 0.5rem 0;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: lighter;
  }

  .team-member-modal {
    display: grid;
    grid-template-columns: 1fr 1fr;
    /* column-gap: 1rem; */
  }

  .team-member-title-modal-desk {
    display: none;
  }
  .team-member-title-modal-mob {
    align-self: center;
    padding: 1rem;
  }

  .team-bio {
    color: rgb(133, 131, 131);
    grid-column: 1/3;
  }

  .ReactModal__Overlay--after-open {
    background-color: rgba(0, 0, 0, 0.687) !important;
  }

  .bio-links-cont {
    display: grid;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
  }

  .bio-link {
    color: rgb(17, 65, 129);
    color: #c2a044;
    margin-right: 1rem;
  }

  .bio-link > img {
    height: 1.45rem;
    width: 1.45rem;
  }

  .bio-links-cont-desk {
    display: none;
  }

  /* @media (max-height: 560px) {
    .team-bio {
      max-height: 220px;
      overflow-y: scroll;
    }
  } */

  .team-select-btn {
    border: none;
    cursor: pointer;
    background-color: transparent;
    outline: none;
    position: relative;
    text-align: left;
    font-size: 1rem;
    color: whitesmoke;
    padding: 0.5rem 1rem;

    /* margin-left: 1rem;
    padding-left: 2rem;
    width: 150px; */
  }

  .team-user-select-cont {
    display: flex;
    flex-direction: row;
    gap: 1rem;

    overflow-x: scroll;
    box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.342);
  }

  .active-team {
    transition: all 0.2s ease-in;
    color: var(--primary-gold);
  }

  /* .team-col-1 {
    background-color: grey !important;
  } */

  /* .team-img {
    filter: none;
    height: 40vh;
    width: 100%;
    margin-top: 10vh;
    object-fit: contain;

    z-index: -1;
  } */

  .team-img {
    object-position: top;
    /* filter: none; */
  }

  .team-links-cont {
    display: flex;
    flex-direction: row;
  }

  .team-email-link {
    text-decoration: none;
    color: var(--primary-gold);
    font-size: 1.25rem;
    font-weight: lighter;
  }

  .team-col-2 {
    padding-top: 0;
  }
}
