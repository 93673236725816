@media (min-width: 769px) {
  .culture-img {
    object-fit: cover !important;
    object-position: -10rem;
  }

  .vision-culture-content-container {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 2rem;
  }

  .vc-list-container {
    width: 100%;
    padding: 0 2rem;
  }

  .vc-title {
    text-align: center;
    color: var(--primary-gold);
    font-family: var(--heading-text) !important;
    margin-right: 1rem;
    margin-bottom: 0;
  }

  .vc-list {
    height: 100%;
    width: 100%;
    margin: 0 auto;

    display: grid;

    list-style-type: disc;
  }

  .vc-list-1 {
    width: 70%;
  }

  .vc-list-2 {
    width: 80%;
  }

  .vc-list > li {
    /* flex: 1; */
    color: whitesmoke;
    margin: 1rem 0;
  }

  .vc-list-container-1 {
    display: grid;
    align-content: center;
    height: 100%;
  }
}

@media (max-width: 768px) {
  .vision-culture-content-container {
    display: grid;
    grid-template-columns: 1fr;
    /* gap: 1rem; */
    padding: 0 2rem;
  }

  .vc-title {
    color: var(--primary-gold);
    font-family: var(--heading-text) !important;
  }

  .vc-list {
    height: 100%;
    padding: 0 1rem;
    /* display: flex;
    flex-direction: column; */
    /* display: grid; */
    /* align-items: start; */
    /* grid-template-rows: 1fr 1fr 1fr 1fr 1fr; */
    /* flex: 1; */
  }

  .vc-list > li {
    /* flex: 1; */
    color: whitesmoke;
    margin: 0.5rem 0;
  }
}
