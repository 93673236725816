@import url("https://fonts.googleapis.com/css2?family=Cinzel:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Alice&display=swap");

:root {
  --careers-img: url("https://storage.googleapis.com/throgmortoncm-files/main-images/careers-min.jpg");
  --primary-blue: #164382;
  --primary-text: "Alice", serif;
  --heading-text: "Cinzel", serif;
  --primary-gold: #c2a044;
  --animate-delay: 1s !important;
  --animate-duration: 1s !important;
}

* {
  padding: 0;
  font-family: var(--primary-text) !important;
  box-sizing: border-box;
  outline: none !important;
}

/* html {
  background-color: #164382;
} */

body {
  max-width: 100vw;
  background-color: #164382;
  margin: 0;
  font-family: var(--primary-text);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  font-family: var(--primary-text);
  line-height: 1.5;
  font-size: 1rem !important;
}

#modal {
  z-index: 999 !important;
}

@media (max-width: 768px) {
  .IN-widget {
    display: inline-block;
    line-height: 1;
    /* vertical-align: bottom; */
    padding: 0px;
    margin: 0px;
    text-indent: 0px;
    /* position: fixed; */
    bottom: 5rem;
    z-index: 999;
    right: 1rem;

    /* border-radius: 0.5rem; */
    /* border: none; */
    /* padding: 1rem; */
  }

  .discover-btn {
    background: transparent;
    border: none;
    color: whitesmoke;
    /* padding: 1rem; */
    /* border: 1px solid whitesmoke; */
    /* border-radius: 1rem; */
    font-size: 1rem;
    text-decoration: none;
    /* padding-bottom: 0.5rem; */
    margin-left: 2rem;
    border-bottom: 1px solid var(--primary-gold);
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    color: lightgray;
  }

  .discover-btn:hover {
    color: white;
    border-bottom: 1px solid white;
  }

  .page-section {
    /* min-height: 100vh; */
    transition: all 0.2s ease;

    background: #164382;
    /* border-bottom: 1px solid #c2a044; */
  }

  .alternate-page-section {
    background-color: aliceblue;
  }

  .alternate-page-section * {
    color: var(--primary-blue) !important;
  }

  .page-header {
    font-size: 2.5rem;
    margin-bottom: 1%;
    color: rgb(255, 255, 255) !important;
    font-weight: 900;
    margin-top: 0;

    /* width: fit-content; */

    text-transform: uppercase;
    /* letter-spacing: 2px; */
    font-family: var(--heading-text), serif !important;
    position: absolute;
    bottom: 0;
    z-index: 99;
    color: #c2a044;
    padding-left: 2rem;
    color: whitesmoke;
  }

  .page-col-1 {
    height: 100%;
    width: 100%;
    position: relative;
    z-index: 1;
    background-color: white;
    /* border-bottom: 3px solid white; */
  }

  .alternate-page-section > .page-col-1 {
    background-color: var(--primary-blue) !important;
  }

  .page-col-2 {
    /* max-height: 100vh; */
    padding: 2rem 0rem;
    overflow-y: hidden;
  }

  .page-col-2 p {
    color: whitesmoke;
  }

  .page-img {
    width: 100%;
    height: 50vh;
    object-fit: cover;
    object-position: middle;

    filter: brightness(0.8);
    /* border-bottom: 3px solid white; */
  }

  .home-container {
    overflow-x: hidden !important;
  }
}

@media (min-width: 769px) {
  .IN-widget {
    display: inline-block;
    line-height: 1;
    vertical-align: bottom;
    padding: 0px;
    margin: 0px;
    text-indent: 0px;
    /* position: fixed; */
    bottom: 5rem;
    z-index: 999;
    right: 1rem;
    /* box-shadow: 0px 2px 5px 1px rgba(17, 30, 43, 0.473); */
    /* border-radius: 0.5rem; */
    /* margin-left: 2rem; */
    margin-top: 0.75rem;
    border: none;
  }

  :root {
    font-size: 14px;
  }

  *::-webkit-scrollbar {
    width: 0.5rem;
    display: none;
  }
  *::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
  *::-webkit-scrollbar-thumb {
    background-color: rgb(80, 80, 80);
    outline: 1px solid slategrey;
  }

  .discover-btn {
    background: transparent;
    border: none;
    color: whitesmoke;
    /* padding: 1rem; */
    /* border: 1px solid whitesmoke; */
    /* border-radius: 1rem; */
    font-size: 1rem;
    text-decoration: none;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid var(--primary-gold);
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    color: lightgray;
  }

  .discover-btn:hover {
    color: white;
    border-bottom: 1px solid gold;
  }

  .page-section {
    scroll-snap-align: start;
    min-height: 100vh;
    max-height: 100vh;
    overflow-y: hidden;

    display: grid;
    grid-template-columns: 30% 70%;
    align-items: center;

    background: #164382;
    background-repeat: no-repeat;
    transition: all 0.2s ease;
    /* border-bottom: 1px solid #c2a044; */
    position: relative;
  }

  .alternate-page-section {
    background-color: aliceblue;
  }

  .alternate-page-section * {
    color: var(--primary-blue) !important;
  }

  .page-header {
    font-size: 2rem;
    color: rgb(255, 255, 255);
    font-weight: 900;
    margin-top: 0;

    width: fit-content;

    border-bottom: 2px solid #c2a044;

    text-transform: uppercase;
    letter-spacing: 2px;
    font-family: var(--heading-text), serif !important;
  }

  .page-col-1 {
    height: 100%;
    width: 100%;
    position: relative;
    z-index: 1;
    background-color: white;
  }

  .page-col-2 {
    max-height: 100vh;
    padding: 10% 10rem;
    overflow-y: auto;
  }

  .page-col-2 p {
    color: whitesmoke;
  }

  .page-img {
    /* position: absolute; */
    width: 100%;
    height: 100vh;
    object-position: center;
    object-fit: scale-down;
  }

  .about-text {
    color: rgb(238, 236, 236);

    font-size: 1rem !important;
    font-weight: 400;
    /* font-family: "Circular Std Book", sans-serif; */
  }

  .pagination-container {
    display: grid;
    /* flex-direction: column; */
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    justify-content: center;
    justify-items: center;
    align-content: center;
  }

  .pagination-item {
    font-size: 1.25rem;
    /* font-weight: bold; */
  }

  .pagination-container-horizontal {
    /* position: relative; */
    margin-bottom: 0.5rem !important;
  }

  .pagination-item-1 {
    position: relative;
    margin-bottom: 1rem;
  }

  .pagination-item-3 {
    position: relative;
    margin-top: 1rem;
  }

  .pagination-container-horizontal > div.pagination-item-1 {
    position: relative;
    margin-bottom: 0rem;
  }
  .pagination-container-horizontal > div.pagination-item-3 {
    margin-top: 0rem;
  }
}

@media (min-width: 800px) {
  :root {
    font-size: 8px;
  }
}
@media (min-width: 1000px) {
  :root {
    font-size: 10px;
  }
}

@media (min-width: 1200px) {
  :root {
    font-size: 12px;
  }
}

@media (min-width: 1300px) {
  :root {
    font-size: 13px;
  }
}

@media (min-width: 1550px) {
  .page-col-2 {
    max-height: 100vh;
    padding: 10% 11rem;
    overflow-y: auto;
  }

  :root {
    font-size: 14px;
  }
}

@media (min-width: 1750px) {
  .page-col-2 {
    max-height: 100vh;
    padding: 10% 11rem;
    overflow-y: auto;
  }

  :root {
    font-size: 16px;
  }
}

@media (min-width: 1850px) {
  .page-col-2 {
    max-height: 100vh;
    padding: 10% 14rem;
    overflow-y: auto;
  }

  :root {
    font-size: 16px;
  }
}

@media (min-width: 2000px) {
  :root {
    font-size: 18px !important;
  }
}

@media (min-width: 2200px) {
  :root {
    font-size: 20px !important;
  }
}
@media (min-width: 2524px) {
  :root {
    font-size: 21px !important;
  }
}
@media (min-width: 2824px) {
  :root {
    font-size: 24px !important;
  }
}

@media (min-width: 3200px) {
  :root {
    font-size: 32px !important;
  }
}

@media (min-width: 4000px) {
  :root {
    font-size: calc(100vw * 0.008) !important;
  }
}

.arrow-container-hero {
  position: absolute;
  z-index: 98;
  bottom: 1rem;
  left: 50%;
}
.arrow-container-page {
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 98;
  right: 2.5rem;

  top: calc(50% - 5.5rem);
}

.arrow-container-horizontal {
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
}

.home-arrow {
  cursor: pointer;
}

.up-arrow-icon:hover {
  animation-name: bounceUpDown;
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  animation-duration: 500ms !important;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

.down-arrow-icon {
  margin-top: 0.25rem;
}

.down-arrow-icon:hover {
  animation-name: bounceDownUp;
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  animation-duration: 500ms !important;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

.right-arrow-icon:hover {
  animation-name: bounceRightLeft;
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  animation-duration: 500ms !important;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}
.left-arrow-icon:hover {
  animation-name: bounceLeftRight;
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  animation-duration: 500ms !important;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

svg.gold > path {
  fill: var(--primary-gold) !important;
  /* stroke: var(--primary-gold) !important; */
}
svg.blue > path {
  fill: var(--primary-blue) !important;
  /* stroke: var(--primary-blue) !important; */
}

.ReactModalPortal {
  position: absolute;
  z-index: 999;
}
.sliderAnimation___300FY {
  transition: transform 1200ms !important;
}

.very-slow {
  animation-duration: 3s !important;
}

.throg-footer-link {
  color: whitesmoke !important;
}
