@media (max-width: 768px) {
  .menu-container {
    width: 100vw;
    position: fixed;
    height: 100vh;
    overflow-y: auto;
    background-color: var(--primary-blue);
    right: 0;
    z-index: 300;
    display: grid;
    grid-template-columns: 1fr;
    /* transition: all 0.2s ease-in-out; */
    transition: all 0.8s ease-in-out;

    box-sizing: border-box;
    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.466);
    padding: 1rem;
    padding-top: 0;

    /* padding-top: 5rem; */
  }

  .menu-content-col {
    height: 100%;
    display: grid;
    align-items: center;
    align-content: center;
    gap: 1rem;
    justify-items: left;
    position: relative;
    z-index: 101;
    padding: 1rem;
    /* padding-bottom: 1rem; */
    border-bottom: 1px solid lightgray;
  }

  .menu-mob-col-1 {
    padding-top: 0;
    padding-bottom: 0;
    gap: 0rem;
  }
  /* .menu-mob-col-1 > div {
    margin-bottom: 1rem;
  } */

  .link-cont-mob {
    margin-bottom: 1rem;
  }

  .menu-address-text {
    margin-left: 0 !important;
  }

  .menu-header {
    position: absolute;
    font-size: 7rem;
    color: rgba(0, 0, 0, 0.13);
    top: 0;
    margin: 0;
  }

  .menu-subheading {
    color: lightgray;
    font-weight: 400;
    letter-spacing: 3px;
    text-transform: uppercase;
    margin-top: 0;
  }

  .menu-footer {
    /* grid-column: 1/3; */
  }

  .menu-footer-link-container {
    display: grid;
    /* flex-direction: column; */
    justify-content: space-between;
    align-items: flex-start;
    padding: 1rem 0;
    gap: 1rem;
    /* width: 88%; */
    color: whitesmoke;
    padding: 1rem;
  }

  .bullet {
    display: none;
  }

  .header-menu {
    position: relative;
    right: 0.5rem;
    top: 0rem;
  }

  .disclosure-text-container {
    padding: 1rem;
    padding-bottom: 5rem;
  }

  .disclosure-text {
    color: rgb(184, 182, 182);
    font-size: 75% !important;
    margin-top: 0;
  }

  .menu-linkedin-img {
    height: 1.5rem;
    width: 1.5rem;
    margin-top: 1rem;
  }

  .linkedin-link {
    /* margin-top: 1rem; */
  }

  .throg-footer-link {
    margin: 0rem;
    font-size: 0.85rem;
    font-weight: 400;
    color: whitesmoke !important;
    text-decoration: none;
    /* margin-right: 1rem; */

    display: block;
    font-family: var(--primary-text);
  }

  .linkedin-container {
    display: grid;
    gap: 1rem;
  }

  .menu-address {
    /* margin-top: 1rem; */
    /* grid-row: 3/4;
    display: grid;
    gap: 1rem; */
    /* padding: 1rem; */
  }

  .locations-header {
    display: none;
  }

  .address-container {
    display: flex;
    flex-direction: row;
    gap: 3rem;
    padding: 1rem;
    flex-wrap: wrap;
  }
}
