@media (min-width: 769px) {
  .downloads-section {
  }

  .downloads-content {
    min-height: 50vh;
    padding: 15% 10%;
    max-height: 100vh;
    overflow: auto;
  }

  .downloads-category {
    padding: 5% 5% 2.5% 5%;
  }

  .downloads-btn {
    font-size: 150%;
    background: none;
    border: none;
    width: 100%;
    text-align: left;
    border-bottom: 1px solid lightgray;
    cursor: pointer;
    outline: none;
    /* font-family: "Circular Std Book", sans-serif; */
    color: #c2a044;
  }

  .downloads-btn:hover {
    color: gold;
  }

  .download-links {
    transition: all 0.2s ease;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    background: none;
  }

  .date-header {
    color: lightgray;
    padding-bottom: 1%;
    border-bottom: 1px solid gold;
    width: fit-content;
    font-size: 125%;
    /* font-family: "Circular Std Book", sans-serif; */
  }

  .download-link {
    color: lightgray;
    font-size: 100%;
    padding: 1%;
    text-decoration: underline !important;
    /* font-family: "Circular Std Book", sans-serif; */
  }

  .download-link:hover {
    color: whitesmoke;
  }

  .Collapsible {
    margin: 5% 0;
  }

  .expand-btn {
    float: right;
  }

  .subscribe-disclaimer-text {
    grid-column: 1/3;
  }

  .sign-up-text {
    font-size: 100% !important;
    color: whitesmoke;
  }

  /* .downloads-col {
    padding: 10% 6rem;
  } */

  .pdfviewer-container {
    position: absolute;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    z-index: 9999;
    padding: 5rem 10rem;
    padding-top: 2rem;
    background-color: rgba(255, 255, 255, 0.6);
  }

  iframe.pdfviewer {
    height: 100%;
    width: 100%;
  }

  svg.close {
    size: 3rem;
    float: right;
    height: 3rem;
    width: 3rem;
    cursor: pointer;
  }

  svg.close > path {
    fill: var(--primary-blue);
    stroke: var(--primary-blue);
  }

  .subscribe-modal-btn {
    padding: 0.5rem;
    /* font-family: "Circular Std Book", sans-serif; */
    width: min-content;
    font-size: 1rem;
    background: #164382;
    color: #c2a044;
    border: none;
    border-radius: 0.25rem;
    cursor: pointer;
    max-width: 100px;
    background: white;
  }

  .subscribe-modal-btn:hover {
    background-color: whitesmoke;
  }

  #mc_embed_signup {
    border-radius: 0.25rem;
    padding: 1rem;
  }

  #mc_embed_signup * {
    /* font-family: "Circular Std Book", sans-serif; */
  }

  .subscribe-header {
    font-size: 150%;

    font-weight: 400;
    color: #164382;
    /* font-family: "Circular Std Book", sans-serif; */
    /* color: whitesmoke; */
    margin-top: 0;
    color: #c2a044;
  }

  .subscribe-form-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }

  .subscribe-input {
    padding: 1rem;
    font-size: 1rem;
    border: none;
    /* border-radius: 0.25rem 0.25rem 0 0; */
    border-radius: 0;
    border-bottom: 3px solid #c2a044;
    background: rgba(92, 102, 142, 0.694) !important;
    transition: all 0.2s ease;
    outline: none !important;
    color: whitesmoke;
  }

  .subscribe-input:valid {
    background: #c2a044 !important;
    border-bottom-color: #164382;
  }

  .subscribe-input::placeholder {
    color: white;
  }

  .subscribe-input:focus {
    background: #c2a044 !important;
    border-bottom-color: #164382;
  }

  input:-internal-autofill-selected {
    background: red !important;
  }

  .subscribe-btn {
    padding: 1rem;
    font-size: 1rem;
    border-radius: 0.25rem;
    background: #164382;
    border: none;
    transition: all 0.2s ease;
    outline: none !important;
    color: whitesmoke;
    cursor: pointer;
  }

  .subscribe-btn:active {
    background: #c2a044;
    color: whitesmoke;
  }

  .subscribe-btn:disabled {
    background: #1643826f;
    cursor: not-allowed;
    color: rgb(240, 235, 235);
  }

  .subscribe-status-container {
    padding: 1rem;
    border-radius: 0.25rem;
  }

  .error {
    background: rgb(227, 66, 66);
    color: whitesmoke;
    grid-column: 1/3;
  }

  .success {
    background: rgb(80, 213, 106);
    color: whitesmoke;
    grid-column: 1/3;
  }

  .close-subscribe-modal-btn {
    position: absolute;
    right: 0rem;
    top: 0rem;
    padding: 0.25rem 0.5rem;
    border: none;
    font-size: 1rem;
    background: none;
    cursor: pointer;
  }
}

.subscribe-disclaimer-text {
  color: rgb(174, 174, 174);
  font-size: 75% !important;
}

/* @media (min-width: 1600px) {
  .downloads-col {
    padding: 10% 14rem;
  }
} */
