@media (max-width: 768px) {
  .hero-section {
    /* width: 100%; */
    width: 100%;
    height: 100vh;

    /* padding: 5%; */

    /* background: url("../media/home-min.jpg"); */
    background: url("https://storage.googleapis.com/throgmortoncm-files/main-images/home-img-min.jpg");

    display: grid;
    background-size: cover;
    background-repeat: no-repeat;
    align-items: center;

    background-size: cover;

    /* align-items: center; */
    background-repeat: no-repeat;
    /* background-position: -1250px -200px; */
    background-position: -1150px -200px;
    /* background-position: 100% 25%; */
    background-size: 450%;
    /* background-size: 200%; */
    border-bottom: 1px solid #c2a044;
    animation-name: imageMove;
    animation-delay: 0ms;
    animation-duration: 5000ms;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
    animation-direction: forwards;
  }

  @keyframes imageMove {
    0% {
      /* filter: blur(5px); */
      background-position: -850px -200px;
      transform: scale(1);
    }
    100% {
      /* filter: blur(0px); */
      background-position: -1150px -200px;
      transform: scale(1);
    }
  }
  /* @keyframes imageMove {
    from {
      transform: translateX(0px);
    }
    to {
      transform: translateX(-300px);
    }
  } */

  .hero-text-container {
    padding-right: 1rem;
  }

  .hero-content-container {
    /* padding: 1rem; */

    height: max-content;

    /* margin-bottom: 1rem; */
    display: grid;
    grid-template-columns: 1fr;
    align-items: center !important;
    justify-items: left;
    background-color: rgba(0, 0, 0, 0.363);
    /* background-color: rgba(0, 0, 0, 0.253); */
    /* display: grid;
    background-color: rgba(17, 66, 129, 0.612); */
    width: 100%;
    height: 100%;
    /* padding: 1rem; */
    /* margin-top: 33%; */
  }

  .hero-span {
    font-weight: 400;
  }

  .hero-sub-header {
    font-size: 1.25rem;
    text-align: left;
    font-weight: 400;
    color: #164382;
    color: white;
    /* padding: 1rem; */
    padding-left: 1rem;
  }

  .welcome-span {
    display: block;
    color: lightgray;
    margin-bottom: 0.5rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 1rem;
    /* font-weight: bold; */
  }

  .hero-title {
    font-weight: 500;
    text-transform: none;
    font-size: 1.25rem;
    letter-spacing: 0px;
    text-transform: uppercase;
    font-family: "Cinzel", sans-serif !important;
    line-height: 1.25;
    color: whitesmoke;
    display: block;
    padding-bottom: 1rem;
  }
}
