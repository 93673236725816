@media (min-width: 769px) {
  .menu-container {
    width: calc(50 * 1rem) !important;
    position: fixed;
    min-height: 100vh;
    background-color: var(--primary-blue);
    right: 0;
    z-index: 100;
    display: grid;
    grid-template-columns: 1fr 1fr;
    transition: all 1s ease-in-out !important;
    /* align-items: top; */

    padding: 3rem 0rem 3rem 5rem;
    /* border: 20px silver; */
    box-sizing: border-box;
    align-items: start;

    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.466);
  }

  .menu-content-col {
    height: 100%;
    display: grid;
    align-items: stretch;
    align-content: start;
    gap: 1rem;
    justify-items: left;
    position: relative;
    z-index: 101;
    padding-bottom: 1rem;
  }

  .menu-address-text {
    margin-left: 0 !important;
  }

  .menu-header {
    position: absolute;
    font-size: 7rem;
    color: rgba(0, 0, 0, 0.13);
    top: 0;
    margin: 0;
  }

  .menu-subheading {
    color: lightgray;
    font-weight: 400;
    letter-spacing: 3px;
    text-transform: uppercase;
  }

  .menu-footer {
    grid-column: 1/3;
  }

  .menu-footer-link-container {
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    gap: 1rem;
    align-items: center;
    padding: 1rem 0;
    width: 75%;
    color: whitesmoke;
  }

  .header-menu {
    display: none;
  }

  .header-link {
    /* margin-right: 1.5vw; */
    font-weight: 400;
    text-decoration: none;
    background: none;
    border: none;
    /* font-family: "Playfair Display", serif; */
    font-size: 1.5rem;
    outline: none;
    border-bottom: 1px solid transparent;
    cursor: pointer;

    /* text-transform: uppercase; */
    /* letter-spacing: 2px; */

    color: #c2a044;

    transition: all 0.2s ease-in;
    color: rgba(245, 245, 245, 0.89);
  }

  .header-link::after {
    content: "";
    position: absolute;
    width: 0%;
    height: 1px;
    background-color: var(--primary-gold);
    left: 0;
    bottom: 0;
  }

  .header-link:hover {
    /* border-bottom: 1px solid gold; */

    color: var(--primary-gold);
  }

  .header-link:hover::after {
    animation: extend 0.2s linear;
    animation-fill-mode: both;
  }
  .header-link:focus::after {
    background-color: var(--primary-blue);
  }

  .throg-footer-link {
    margin: 0rem;
    font-size: 0.85rem;
    font-weight: 400;
    color: whitesmoke !important;
    text-decoration: none;
    /* margin-right: 1rem; */

    display: block;
    font-family: var(--primary-text);
  }

  .throg-footer-link:hover {
    color: var(--primary-gold) !important;
  }

  .linkedin-link {
    margin-top: 1rem;
  }

  .disclosure-text-container {
    display: grid;
    gap: 1rem;
    margin-right: 8rem;
  }

  .disclosure-text {
    color: rgb(184, 182, 182);
    margin: 0;
    font-size: 75% !important;
  }

  .menu-linkedin-img {
    height: 1.5rem;
    width: 1.5rem;
  }

  .linkedin-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    display: grid;
    grid-template-columns: 1fr;
  }

  .linkedin-follow {
    margin-top: 0.1rem;
  }

  .menu-address {

    grid-column: 1/3;
  }

  .locations-header {
    color: lightgray;
    /* text-decoration: underline; */
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 0;
    /* grid-column: 1/3; */
    font-weight: 400;
  }

  .address-container {
    display: flex;
    flex-direction: row;
    gap: 3rem;
    padding: 1rem 0;

  }
}

@media (min-width: 1400px) {
  .menu-container {
    width: 50vw;
    padding-left: 5rem;
  }
}

@media (min-width: 2000px) {
  .menu-container {
    width: 55vw;
    padding-left: 5rem;
  }
}
