@media (max-width: 768px) {
  .best-execution-section {
    padding: 15% 5%;
    background: rgb(244, 244, 244);
  }

  .best-execution-section > *,
  .best-execution-section a,
  .best-exection-section p {
    font-family: "Circular Std Book", sans-serif;
  }

  .best-execution-header {
    font-size: 200%;
    font-family: var(--heading-text) !important;
  }

  strong {
    font-family: var(--heading-text) !important;
  }

  .best-execution-section > h2 {
    text-decoration: underline;
  }

  .best-execution-section > ul {
    padding-left: 1.5rem;
  }
  .best-execution-section > ul > li {
    font-family: "Circular Std Book", sans-serif;
  }
}
