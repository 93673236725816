@media (min-width: 769px) {
  .careers-section {
  }

  .careers-bg-img {
    background: url("https://storage.googleapis.com/throgmortoncm-files/main-images/careers-min.jpg");
    height: 100%;
    width: 100%;

    background-size: cover;
    background-position-y: 0%;
    background-position-x: 50%;
    padding: 15%;

    background-repeat: no-repeat;
  }

  .careers-article {
    color: whitesmoke;
    font-size: 125%;

    padding: 15% 5%;

    height: max-content;
  }

  .recruitment-link {
    color: var(--primary-gold) !important;
  }

  .recruitment-link:hover {
    color: gold;
  }

  .careers-img {
    object-fit: cover;
  }
}
