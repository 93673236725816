@media (max-width: 769px) {
  .client-login-container {
    position: fixed;
    bottom: 1rem;
    /* background-color: rgb(245, 245, 245); */
    /* width: 100vw; */
    right: 0;
    padding: 1rem 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    z-index: 202;
  }

  .client-login-link {
    background-color: var(--primary-blue);
    color: whitesmoke;
    padding: 1rem;
    /* border: 1px solid lightgray; */
    box-shadow: 0px 2px 5px 1px rgba(17, 30, 43, 0.473);
    border-radius: 0.5rem;
    margin: 1rem;
    font-size: 1rem;
    text-decoration: none;
  }

  .client-login-link:hover {
    background-color: goldenrod;
    color: var(--primary-blue);
  }

  .client-login-co-title {
    padding: 0;
    margin: 0;
    padding-left: 1rem;
  }
}
