@media (min-width: 769px) {
  .form-container {
    /* grid-column: 1/3; */
  }

  .contact-form {
    display: grid;
    gap: 1rem;
    grid-template-columns: 1fr;
    align-items: center;
  }

  .contact-form-label {
    color: lightgray !important;
    display: block;
    /* text-transform: uppercase; */
    /* letter-spacing: 2px; */
  }

  .contact-form-input {
    background-color: rgba(255, 255, 255, 0.308);
    border: none;
    border-bottom: 1px solid lightgray;
    transition: all 0.2s ease-in-out;

    -webkit-appearance: none;
    -webkit-border-radius: 0;
    border-radius: 0;
    font-size: 1rem;
  }

  textarea {
    font-size: 1rem;
  }

  .contact-form-input:focus,
  .contact-form-text-area:focus {
    background-color: goldenrod;
    color: var(--primary-blue);
  }

  .contact-form-input::placeholder,
  .contact-form-text-area::placeholder {
    color: whitesmoke;
  }

  /* .contact-form-input:valid,
  .contact-form-text-area:valid {
    background-color: goldenrod;
    color: var(--primary-blue);
  } */
  .contact-form-input:active,
  .contact-form-text-area:active {
    background-color: goldenrod;
    color: var(--primary-blue);
  }

  .contact-form-input:invalid,
  .contact-form-text-area:invalid {
    background-color: rgb(218, 64, 64);
    color: var(--primary-blue);
  }

  .contact-form-input,
  .contact-form-text-area {
    padding: 1rem;
    width: 100%;
    outline: none;
  }

  .contact-form-text-area {
    /* max-height: 40%; */
    height: 100%;
    overflow: auto;
    resize: none;
    background-color: rgba(255, 255, 255, 0.308);
    border: none;
    border-bottom: 1px solid lightgray;
    -webkit-appearance: none;
    -webkit-border-radius: 0;
    border-radius: 0;
  }

  .contact-form-btn {
    padding: 1rem 0;

    color: whitesmoke;
    background-color: aliceblue;
    /* border: 1px solid lightgray; */
    border: none;
    border-radius: 0.25rem;

    cursor: pointer;
    width: 100%;
    /* margin-top: 2rem; */
    transition: all 0.2s ease-in-out;
    color: var(--primary-blue);
  }

  .contact-form-btn:disabled {
    background-color: lightgray;
    cursor: not-allowed;
  }

  .contact-form-btn:hover {
    color: var(--primary-blue);
    background-color: goldenrod;
  }

  .error-msg-container {
    /* grid-column: 1/3; */
  }

  .error-msg-container > p {
    color: rgb(219, 63, 63);
  }
}

@media (min-width: 1600px) {
  .contact-form-input,
  .contact-form-text-area {
    padding: 1rem;
  }
}
