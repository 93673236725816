.media-video {
  width: 100%;
}

.video-card {
  border-bottom: 1px solid var(--primary-gold);
  flex-basis: 500px;
  flex-shrink: 0;
  /* width: 500px; */
  scroll-snap-align: start;
  flex: none;
}

.media-container {
  display: flex;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  width: 100%;
  flex-flow: row nowrap;
  /* grid-template-columns: 1fr ; */
  gap: 3rem;
}

.video-date {
  text-decoration: underline;
  margin-bottom: 1.5rem;
}

.video-title {
  margin-bottom: 0.5rem;
  margin-top: 0rem;
  font-size: 1.5rem !important;
  color: var(--primary-gold) !important;
}

.video-description {
  margin-top: 0.25rem;
}

.video-subtitle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;
}

/* .highlighted-video {
  box-shadow: 0px 0px 20px 5px rgba(0, 0, 0, 0.308);
} */

@media (min-width: 851px) {
  .video-card {
    margin: 0 0.5rem;
  }
}

@media (max-width: 850px) {
  .media-container {
    display: grid;
    grid-template-columns: 1fr;
    padding: 1rem 2rem;
    gap: 2rem;
  }

  .video-card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }

  .video-subtitle {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 1rem;
    font-size: 1rem;
  }

  .highlighted-video {
    animation-name: flash;
    animation-duration: 2s;
    animation-timing-function: ease-in-out;
  }

  @keyframes flash {
    from {
      background-color: lightgray;
    }
    to {
      background-color: transparent;
    }
  }
}
