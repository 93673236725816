@media (max-width: 768px) {
  .privacy-section {
    padding: 15% 5%;
    background: rgb(244, 244, 244);
  }

  .privacy-header {
    font-family: var(--heading-text) !important;
  }

  .privacy-section strong {
    font-family: var(--primary-text) !important;
  }

  .privacy-section > *,
  a {
  }

  .privacy-header {
    font-size: 200%;
    font-family: var(--heading-text) !important;
  }

  .privacy-section h2,
  h3 {
    /* text-decoration: underline; */
    font-family: var(--heading-text) !important;
    font-size: 1rem;
  }

  .privacy-section > ul {
    padding-left: 1.5rem;
  }
  .privacy-section > ul > li {
  }

  address {
    font-style: normal;
  }
}
