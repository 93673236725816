@media (max-width: 768px) {
  #about {
  }

  .about-content-container {
    background: url("https://storage.googleapis.com/throgmortoncm-files/main-images/what-we-do-min.jpg"),
      rgb(30, 30, 40);
    height: 30vh;
    width: 100%;

    background-size: cover;
    background-position-y: 50%;
    padding: 25% 5%;
    padding: 0 5%;
    display: grid;
    align-items: end;

    background-repeat: no-repeat;
  }

  .about-text-container {
    padding: 5%;
    border-top: 1px solid #c2a044;
  }

  .about-text {
    color: whitesmoke;
    font-size: 125%;
    padding: 0 2rem;
    margin-top: 0;
  }
}
