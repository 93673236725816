@import url(https://fonts.googleapis.com/css2?family=Cinzel:wght@400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Alice&display=swap);
@-webkit-keyframes bounceUpDown {
  from {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }

  to {
    -webkit-transform: translateY(-1rem);
            transform: translateY(-1rem);
  }
}

@keyframes bounceUpDown {
  from {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }

  to {
    -webkit-transform: translateY(-1rem);
            transform: translateY(-1rem);
  }
}

@-webkit-keyframes bounceDownUp {
  from {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }

  to {
    -webkit-transform: translateY(1rem);
            transform: translateY(1rem);
  }
}

@keyframes bounceDownUp {
  from {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }

  to {
    -webkit-transform: translateY(1rem);
            transform: translateY(1rem);
  }
}

@-webkit-keyframes bounceLeftRight {
  from {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }

  to {
    -webkit-transform: translateX(-1rem);
            transform: translateX(-1rem);
  }
}

@keyframes bounceLeftRight {
  from {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }

  to {
    -webkit-transform: translateX(-1rem);
            transform: translateX(-1rem);
  }
}
@-webkit-keyframes bounceRightLeft {
  from {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }

  to {
    -webkit-transform: translateX(1rem);
            transform: translateX(1rem);
  }
}
@keyframes bounceRightLeft {
  from {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }

  to {
    -webkit-transform: translateX(1rem);
            transform: translateX(1rem);
  }
}

.custom-animation {
  -webkit-animation-name: slideThenFadeIn;
          animation-name: slideThenFadeIn;
  -webkit-animation-duration: 5s;
          animation-duration: 5s;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  /* animation-fill-mode: forwards; */
}

@-webkit-keyframes slideThenFadeIn {
  0% {
    -webkit-transform: translateX(-100vw);
            transform: translateX(-100vw);
    opacity: 0;
  }

  37% {
    -webkit-transform: translateX(0vw);
            transform: translateX(0vw);
    /* opacity: 0.5; */
  }

  75% {
    opacity: 1;
  }

  100% {
  }
}

@keyframes slideThenFadeIn {
  0% {
    -webkit-transform: translateX(-100vw);
            transform: translateX(-100vw);
    opacity: 0;
  }

  37% {
    -webkit-transform: translateX(0vw);
            transform: translateX(0vw);
    /* opacity: 0.5; */
  }

  75% {
    opacity: 1;
  }

  100% {
  }
}

:root {
  --careers-img: url("https://storage.googleapis.com/throgmortoncm-files/main-images/careers-min.jpg");
  --primary-blue: #164382;
  --primary-text: "Alice", serif;
  --heading-text: "Cinzel", serif;
  --primary-gold: #c2a044;
  --animate-delay: 1s !important;
  --animate-duration: 1s !important;
}

* {
  padding: 0;
  font-family: "Alice", serif !important;
  font-family: var(--primary-text) !important;
  box-sizing: border-box;
  outline: none !important;
}

/* html {
  background-color: #164382;
} */

body {
  max-width: 100vw;
  background-color: #164382;
  margin: 0;
  font-family: "Alice", serif;
  font-family: var(--primary-text);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  font-family: "Alice", serif;
  font-family: var(--primary-text);
  line-height: 1.5;
  font-size: 1rem !important;
}

#modal {
  z-index: 999 !important;
}

@media (max-width: 768px) {
  .IN-widget {
    display: inline-block;
    line-height: 1;
    /* vertical-align: bottom; */
    padding: 0px;
    margin: 0px;
    text-indent: 0px;
    /* position: fixed; */
    bottom: 5rem;
    z-index: 999;
    right: 1rem;

    /* border-radius: 0.5rem; */
    /* border: none; */
    /* padding: 1rem; */
  }

  .discover-btn {
    background: transparent;
    border: none;
    color: whitesmoke;
    /* padding: 1rem; */
    /* border: 1px solid whitesmoke; */
    /* border-radius: 1rem; */
    font-size: 1rem;
    text-decoration: none;
    /* padding-bottom: 0.5rem; */
    margin-left: 2rem;
    border-bottom: 1px solid #c2a044;
    border-bottom: 1px solid var(--primary-gold);
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    color: lightgray;
  }

  .discover-btn:hover {
    color: white;
    border-bottom: 1px solid white;
  }

  .page-section {
    /* min-height: 100vh; */
    transition: all 0.2s ease;

    background: #164382;
    /* border-bottom: 1px solid #c2a044; */
  }

  .alternate-page-section {
    background-color: aliceblue;
  }

  .alternate-page-section * {
    color: #164382 !important;
    color: var(--primary-blue) !important;
  }

  .page-header {
    font-size: 2.5rem;
    margin-bottom: 1%;
    color: rgb(255, 255, 255) !important;
    font-weight: 900;
    margin-top: 0;

    /* width: fit-content; */

    text-transform: uppercase;
    /* letter-spacing: 2px; */
    font-family: "Cinzel", serif, serif !important;
    font-family: var(--heading-text), serif !important;
    position: absolute;
    bottom: 0;
    z-index: 99;
    color: #c2a044;
    padding-left: 2rem;
    color: whitesmoke;
  }

  .page-col-1 {
    height: 100%;
    width: 100%;
    position: relative;
    z-index: 1;
    background-color: white;
    /* border-bottom: 3px solid white; */
  }

  .alternate-page-section > .page-col-1 {
    background-color: #164382 !important;
    background-color: var(--primary-blue) !important;
  }

  .page-col-2 {
    /* max-height: 100vh; */
    padding: 2rem 0rem;
    overflow-y: hidden;
  }

  .page-col-2 p {
    color: whitesmoke;
  }

  .page-img {
    width: 100%;
    height: 50vh;
    object-fit: cover;
    object-position: middle;

    -webkit-filter: brightness(0.8);

            filter: brightness(0.8);
    /* border-bottom: 3px solid white; */
  }

  .home-container {
    overflow-x: hidden !important;
  }
}

@media (min-width: 769px) {
  .IN-widget {
    display: inline-block;
    line-height: 1;
    vertical-align: bottom;
    padding: 0px;
    margin: 0px;
    text-indent: 0px;
    /* position: fixed; */
    bottom: 5rem;
    z-index: 999;
    right: 1rem;
    /* box-shadow: 0px 2px 5px 1px rgba(17, 30, 43, 0.473); */
    /* border-radius: 0.5rem; */
    /* margin-left: 2rem; */
    margin-top: 0.75rem;
    border: none;
  }

  :root {
    font-size: 14px;
  }

  *::-webkit-scrollbar {
    width: 0.5rem;
    display: none;
  }
  *::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
  *::-webkit-scrollbar-thumb {
    background-color: rgb(80, 80, 80);
    outline: 1px solid slategrey;
  }

  .discover-btn {
    background: transparent;
    border: none;
    color: whitesmoke;
    /* padding: 1rem; */
    /* border: 1px solid whitesmoke; */
    /* border-radius: 1rem; */
    font-size: 1rem;
    text-decoration: none;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid #c2a044;
    border-bottom: 1px solid var(--primary-gold);
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    color: lightgray;
  }

  .discover-btn:hover {
    color: white;
    border-bottom: 1px solid gold;
  }

  .page-section {
    scroll-snap-align: start;
    min-height: 100vh;
    max-height: 100vh;
    overflow-y: hidden;

    display: grid;
    grid-template-columns: 30% 70%;
    align-items: center;

    background: #164382;
    background-repeat: no-repeat;
    transition: all 0.2s ease;
    /* border-bottom: 1px solid #c2a044; */
    position: relative;
  }

  .alternate-page-section {
    background-color: aliceblue;
  }

  .alternate-page-section * {
    color: #164382 !important;
    color: var(--primary-blue) !important;
  }

  .page-header {
    font-size: 2rem;
    color: rgb(255, 255, 255);
    font-weight: 900;
    margin-top: 0;

    width: -webkit-fit-content;

    width: -moz-fit-content;

    width: fit-content;

    border-bottom: 2px solid #c2a044;

    text-transform: uppercase;
    letter-spacing: 2px;
    font-family: "Cinzel", serif, serif !important;
    font-family: var(--heading-text), serif !important;
  }

  .page-col-1 {
    height: 100%;
    width: 100%;
    position: relative;
    z-index: 1;
    background-color: white;
  }

  .page-col-2 {
    max-height: 100vh;
    padding: 10% 10rem;
    overflow-y: auto;
  }

  .page-col-2 p {
    color: whitesmoke;
  }

  .page-img {
    /* position: absolute; */
    width: 100%;
    height: 100vh;
    object-position: center;
    object-fit: scale-down;
  }

  .about-text {
    color: rgb(238, 236, 236);

    font-size: 1rem !important;
    font-weight: 400;
    /* font-family: "Circular Std Book", sans-serif; */
  }

  .pagination-container {
    display: grid;
    /* flex-direction: column; */
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    justify-content: center;
    justify-items: center;
    align-content: center;
  }

  .pagination-item {
    font-size: 1.25rem;
    /* font-weight: bold; */
  }

  .pagination-container-horizontal {
    /* position: relative; */
    margin-bottom: 0.5rem !important;
  }

  .pagination-item-1 {
    position: relative;
    margin-bottom: 1rem;
  }

  .pagination-item-3 {
    position: relative;
    margin-top: 1rem;
  }

  .pagination-container-horizontal > div.pagination-item-1 {
    position: relative;
    margin-bottom: 0rem;
  }
  .pagination-container-horizontal > div.pagination-item-3 {
    margin-top: 0rem;
  }
}

@media (min-width: 800px) {
  :root {
    font-size: 8px;
  }
}
@media (min-width: 1000px) {
  :root {
    font-size: 10px;
  }
}

@media (min-width: 1200px) {
  :root {
    font-size: 12px;
  }
}

@media (min-width: 1300px) {
  :root {
    font-size: 13px;
  }
}

@media (min-width: 1550px) {
  .page-col-2 {
    max-height: 100vh;
    padding: 10% 11rem;
    overflow-y: auto;
  }

  :root {
    font-size: 14px;
  }
}

@media (min-width: 1750px) {
  .page-col-2 {
    max-height: 100vh;
    padding: 10% 11rem;
    overflow-y: auto;
  }

  :root {
    font-size: 16px;
  }
}

@media (min-width: 1850px) {
  .page-col-2 {
    max-height: 100vh;
    padding: 10% 14rem;
    overflow-y: auto;
  }

  :root {
    font-size: 16px;
  }
}

@media (min-width: 2000px) {
  :root {
    font-size: 18px !important;
  }
}

@media (min-width: 2200px) {
  :root {
    font-size: 20px !important;
  }
}
@media (min-width: 2524px) {
  :root {
    font-size: 21px !important;
  }
}
@media (min-width: 2824px) {
  :root {
    font-size: 24px !important;
  }
}

@media (min-width: 3200px) {
  :root {
    font-size: 32px !important;
  }
}

@media (min-width: 4000px) {
  :root {
    font-size: calc(100vw * 0.008) !important;
  }
}

.arrow-container-hero {
  position: absolute;
  z-index: 98;
  bottom: 1rem;
  left: 50%;
}
.arrow-container-page {
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 98;
  right: 2.5rem;

  top: calc(50% - 5.5rem);
}

.arrow-container-horizontal {
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
}

.home-arrow {
  cursor: pointer;
}

.up-arrow-icon:hover {
  -webkit-animation-name: bounceUpDown;
          animation-name: bounceUpDown;
  -webkit-animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
          animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
          animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -webkit-animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
          animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -webkit-animation-duration: 500ms !important;
          animation-duration: 500ms !important;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
          animation-direction: alternate;
}

.down-arrow-icon {
  margin-top: 0.25rem;
}

.down-arrow-icon:hover {
  -webkit-animation-name: bounceDownUp;
          animation-name: bounceDownUp;
  -webkit-animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
          animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
          animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -webkit-animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
          animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -webkit-animation-duration: 500ms !important;
          animation-duration: 500ms !important;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
          animation-direction: alternate;
}

.right-arrow-icon:hover {
  -webkit-animation-name: bounceRightLeft;
          animation-name: bounceRightLeft;
  -webkit-animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
          animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
          animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -webkit-animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
          animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -webkit-animation-duration: 500ms !important;
          animation-duration: 500ms !important;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
          animation-direction: alternate;
}
.left-arrow-icon:hover {
  -webkit-animation-name: bounceLeftRight;
          animation-name: bounceLeftRight;
  -webkit-animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
          animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
          animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -webkit-animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
          animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -webkit-animation-duration: 500ms !important;
          animation-duration: 500ms !important;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
          animation-direction: alternate;
}

svg.gold > path {
  fill: #c2a044 !important;
  fill: var(--primary-gold) !important;
  /* stroke: var(--primary-gold) !important; */
}
svg.blue > path {
  fill: #164382 !important;
  fill: var(--primary-blue) !important;
  /* stroke: var(--primary-blue) !important; */
}

.ReactModalPortal {
  position: absolute;
  z-index: 999;
}
.sliderAnimation___300FY {
  transition: -webkit-transform 1200ms !important;
  transition: transform 1200ms !important;
  transition: transform 1200ms, -webkit-transform 1200ms !important;
}

.very-slow {
  -webkit-animation-duration: 3s !important;
          animation-duration: 3s !important;
}

.throg-footer-link {
  color: whitesmoke !important;
}

#icon {
  outline: none !important;
  width: 100px;
  height: 100px;
  cursor: pointer;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

@media (max-width: 768px) {
  .menu-icon {
    position: fixed;
    right: 0;
    z-index: 306;
    width: 75px;
    height: 50px;
    /* padding: 0.25rem; */
  }

  path.menu-icon-path {
    fill: none;
    transition: stroke-dashoffset 0.4s cubic-bezier(0.25, -0.25, 0.75, 1.25),
      stroke-dasharray 0.4s cubic-bezier(0.25, -0.25, 0.75, 1.25);
    stroke-width: 20px;
    stroke-linecap: round;
    stroke: goldenrod;
    stroke-dashoffset: 0px;
  }
}

@media (min-width: 769px) {
  #icon {
    width: 6rem;
    height: 6rem;
  }

  .menu-icon {
    position: fixed;
    right: 0;
    z-index: 201;
  }

  path.menu-icon-path {
    fill: none;
    transition: stroke-dashoffset 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25),
      stroke-dasharray 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25);
    stroke-width: 20px;
    stroke-linecap: round;
    stroke: goldenrod;
    stroke-dashoffset: 0px;
  }
}

path#top,
path#bottom {
  stroke-dasharray: 240px 950px;
}
path#middle {
  stroke-dasharray: 240px 240px;
  /* display: none; */
}

.cross path {
  stroke: golden;
}

.cross path#top,
.cross path#bottom {
  stroke-dashoffset: -650px;
  stroke-dashoffset: -650px;
}
.cross path#middle {
  /* stroke-dashoffset: -115px;
    stroke-dasharray: 1px 220px; */
  display: none;
}

.loading-view {
  text-align: center;
  padding: 1rem;
  height: 100vh;
  width: 100%;
  background: #164382;
  background: whitesmoke;
  margin: 0;
}

.loading-view > img {
  width: 325px;
  height: 88px;
}

.loader,
.loader:after {
  border-radius: 50%;
  width: 5em;
  height: 5em;
}
.loader {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 0.5em solid #c2a044;
  border-right: 0.5em solid #c2a044;
  border-bottom: 0.5em solid #c2a044;
  border-left: 0.5em solid #164382;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media (max-width: 768px) {
}
@media (min-width: 769px) {
  .App {
    height: 100vh;
  }
}

@media (max-width: 768px) {
  .throg-header {
    position: fixed;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    z-index: 99;
    transition: all 0.2s ease;
    /* z-index: 305; */
    padding-left: 1rem;
    /* background-color: rgb(238, 238, 238);
    box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.123); */
  }

  .header-link-container {
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    grid-gap: 3rem;
    gap: 3rem;
  }

  .login-link {
    font-weight: 600;
    text-decoration: none;
    padding-bottom: 1%;
    font-size: 150%;
    background: none;
    border: none;
    /* font-family: "Playfair Display", serif; */

    text-transform: uppercase;
    letter-spacing: 2px;

    color: #c2a044;
  }

  .header-link {
    /* margin-right: 1.5vw; */
    font-weight: 400;
    text-decoration: none;
    background: none;
    border: none;
    /* font-family: "Playfair Display", serif; */
    font-family: var(--primary-text);
    font-size: 1.5rem;
    outline: none;
    border-bottom: 1px solid transparent;
    cursor: pointer;

    /* text-transform: uppercase; */
    /* letter-spacing: 2px; */

    color: #c2a044;

    transition: all 0.2s ease-in;
    color: rgba(245, 245, 245, 0.89);
  }

  .header-link::after {
    content: "";
    position: absolute;
    width: 0%;
    height: 1px;
    background-color: gold;
    left: 0;
    bottom: 0;
  }

  /* .header-link::after:hover {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: gold;
    left: 50%;
    bottom: 0;
  } */

  .header-link:hover {
    /* border-bottom: 1px solid gold; */

    color: gold;
  }

  .header-link:hover::after {
    -webkit-animation: extend 0.2s linear;
            animation: extend 0.2s linear;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
  }

  .throg-logo-header {
    height: 4rem !important;
    width: calc(4rem * 3.69) !important;
  }

  .services-link-container {
    position: relative;
  }

  .services-links-list {
    list-style: none;

    padding: 5% 0%;
    transition: all 0.2s ease;
    margin-top: -1%;
  }
  .services-links-list > li {
    padding: 5%;
  }

  .header-link-container-mob {
    position: fixed;

    grid-gap: 1rem;

    gap: 1rem;

    height: 100vh !important;

    background: rgb(249, 247, 247);

    width: 100%;
    padding: 5% 5%;

    overflow-y: auto;
    left: -100%;
    transition: all 0.2s ease;
  }

  .header-link-container-mob > div {
    margin: 5% 0;
  }

  .open-btn {
    padding: 0%;
    background: none;
    font-size: 28px;
    border: none;
    padding-left: 1rem;

    align-self: center;
    justify-self: center;
    margin-right: 5vw;
    margin-bottom: 2vh;
    color: rgb(18, 18, 65);
  }

  .close-btn {
    position: absolute;
    right: 1rem;
    padding: 0.25rem;
    font-size: 200%;
    border: none;
    background: none;
    z-index: 999;
    color: rgb(18, 18, 65);
  }
}

@media (min-width: 769px) {
  .throg-header {
    position: fixed;
    min-height: 10%;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    padding: 0;
    z-index: 99;
    transition: all 0.2s ease;
    /* max-height: 10vh; */
    /* background-color: whitesmoke; */
    /* padding-bottom: 0.5rem; */
  }

  .throg-logo-header {
    height: 5rem;
    width: calc(5rem * 3.69);
    margin-left: 1.5rem;
  }

  .header-link-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-items: flex-end;
  }

  .login-link {
    margin-right: 5vw;

    text-decoration: none;

    cursor: pointer;
    padding-bottom: 1%;
    font-weight: 400;

    text-transform: uppercase;
    letter-spacing: 2px;

    color: rgb(29, 93, 114);
    color: #c2a044;
  }

  .login-link:hover {
    border-bottom: 1px solid gold;
    color: #7b6320;
    color: gold;
  }

  .header-link {
    /* margin-right: 1.5vw; */
    font-weight: 400;
    text-decoration: none;
    background: none;
    border: none;
    /* font-family: "Playfair Display", serif; */
    font-size: 1.5rem;
    outline: none;
    border-bottom: 1px solid transparent;
    cursor: pointer;

    /* text-transform: uppercase; */
    /* letter-spacing: 2px; */

    color: #c2a044;

    transition: all 0.2s ease-in;
    color: rgba(245, 245, 245, 0.89);
  }

  .header-link::after {
    content: "";
    position: absolute;
    width: 0%;
    height: 1px;
    background-color: gold;
    left: 0;
    bottom: 0;
  }

  /* .header-link::after:hover {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: gold;
    left: 50%;
    bottom: 0;
  } */

  .header-link:hover {
    /* border-bottom: 1px solid gold; */

    color: gold;
  }

  .header-link:hover::after {
    -webkit-animation: extend 0.2s linear;
            animation: extend 0.2s linear;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
  }

  .home-link {
    color: rgb(17, 65, 129) !important;
  }

  .services-link-container {
    position: relative;
  }

  .services-links-list {
    position: absolute;
    list-style: none;

    width: -webkit-max-content;

    width: max-content;
    min-width: 250%;
    padding: 2rem 0;
    transition: all 0.2s ease;
    margin-top: 0%;
  }
  .services-links-list > li {
    padding: 1rem;

    background: rgb(249, 247, 247);

    color: rgb(17, 63, 142);
  }

  @supports (-ms-ime-align: auto) {
    .throg-header {
      height: 95px;
    }
  }
}

@media (min-width: 1600px) {
  /* .throg-logo-header {
    height: 88px;
    width: 324px;
    margin-left: 1.5rem;
  } */
}

@-webkit-keyframes extend {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}

@keyframes extend {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}

@media (max-width: 768px) {
  #about {
  }

  .about-content-container {
    background: url("https://storage.googleapis.com/throgmortoncm-files/main-images/what-we-do-min.jpg"),
      rgb(30, 30, 40);
    height: 30vh;
    width: 100%;

    background-size: cover;
    background-position-y: 50%;
    padding: 25% 5%;
    padding: 0 5%;
    display: grid;
    align-items: end;

    background-repeat: no-repeat;
  }

  .about-text-container {
    padding: 5%;
    border-top: 1px solid #c2a044;
  }

  .about-text {
    color: whitesmoke;
    font-size: 125%;
    padding: 0 2rem;
    margin-top: 0;
  }
}

@media (min-width: 769px) {
  #about {
  }
}

.about-text > a {
  color: #c2a044;
  /* font-family: "Circular Std Book", sans-serif; */
}

.about-text > a:hover {
  color: gold;
}

@media (min-width: 769px) {
  #contact {
  }

  .contact-container {
    padding: 15% 5%;
    padding: 15% 10%;
    grid-gap: 1rem;
    gap: 1rem;
  }

  .contact-link {
    color: whitesmoke !important;
    font-size: 100%;
    text-decoration: none;
    padding: 2.5% 0;
    text-decoration: underline;
  }

  .contact-link:hover {
    color: var(--primary-gold);
  }

  .address-text {
    color: white;
    margin: 0;

    padding: 0;
  }

  .address-text:nth-of-type(1) {
    margin-left: 0;
  }

  iframe.map {
    width: 100%;
    height: 100%;
  }

  .contact-content-container {
    width: 100%;
    display: grid;
    grid-template-columns: 60% 1fr;
    grid-gap: 3rem;
    gap: 3rem;
    align-items: top;
    grid-row-gap: 1rem;
    row-gap: 1rem;
  }

  .contact-links {
    grid-column: 1/3;
    display: grid;
    grid-gap: 1rem;
    gap: 1rem;
    align-content: center;
    grid-template-columns: 1fr 1fr;
  }
  .contact-links .contact-form-label {
    margin-bottom: 1rem;
    text-transform: uppercase;
  }

  .contact-form-label {
    letter-spacing: 3px;
  }

  .contact-label__locations {
    color: lightgray !important;
    display: block;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 1rem;
  }

  .linkedin-link-contact {
    margin-top: 1rem !important;
  }

  #map {
    width: 100%;
    height: auto;
  }
}

.contact-link:hover {
  color: var(--primary-gold) !important;
}

.contact-title-label {
  color: lightgray;
  text-transform: uppercase;

  font-size: 1.25rem;
}

.location-container {
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
}

.location-city {
  color: lightgray;
  text-decoration: underline;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin: 0;
  font-weight: normal;
}

@media (min-width: 768px) {
  .locations-container {
   grid-column: 1/3;
  }
}

@media (max-width: 768px) {
  #contact {
    align-items: center;
    /* border-bottom: 1px solid #c2a044; */
  }

  .contact-content-container {
    display: grid;
    grid-gap: 2rem;
    gap: 2rem;
  }

  .contact-bg-img {
    padding: 25% 5%;

    background: url("https://storage.googleapis.com/throgmortoncm-files/main-images/contact-min.jpg");
    height: 30vh;
    width: 100%;

    background-size: cover;
    background-position-y: 30%;
    padding: 0 5%;
    display: grid;
    align-items: end;

    background-repeat: no-repeat;
  }

  .contact-links-container {
    display: grid;
    padding: 10% 5%;
    border-top: 1px solid #c2a044;
  }

  .contact-link {
    color: whitesmoke !important;
    font-size: 105%;
    text-decoration: none;
    padding: 5% 0 0 0;
    font-family: var(--primary-text), serif;
    text-decoration: underline;
  }

  .contact-link:hover {
    text-decoration: underline;
  }

  .contact-links {
    /* margin-top: 1rem; */
    grid-row: 1/2;
    display: grid;
    grid-gap: 1rem;
    gap: 1rem;
    padding: 0 2rem;
  }

  .address-text {
    font-size: 105%;
    color: white;
    margin: 0;
    /* margin-left: calc(2.5% + 1.05rem); */
    padding: 0;
  }

  /* .address-text:nth-of-type(1) {
    margin-left: 0;
    margin-top: 5%;
  } */

  iframe.map {
    width: calc(100vw - 4rem);
    /* height: 50vh; */
    height: calc(100vw - 8rem);
    margin: 0 2rem;
    box-sizing: border-box;
  }

  .contact-links .contact-form-label {
    margin-bottom: 1rem;
    text-transform: uppercase;
  }

  .contact-form-label {
    letter-spacing: 3px;
  }

  .contact-label__locations {
    color: lightgray !important;
    display: block;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 1rem;
  }

  #map {
    width: 100%;
    height: 300px;
  }

  .map-wrapper {
    padding: 0 2rem;
  }

  .locations-container {
    padding: 0 1rem;
  }
}

@media (min-width: 769px) {
  .form-container {
    /* grid-column: 1/3; */
  }

  .contact-form {
    display: grid;
    grid-gap: 1rem;
    gap: 1rem;
    grid-template-columns: 1fr;
    align-items: center;
  }

  .contact-form-label {
    color: lightgray !important;
    display: block;
    /* text-transform: uppercase; */
    /* letter-spacing: 2px; */
  }

  .contact-form-input {
    background-color: rgba(255, 255, 255, 0.308);
    border: none;
    border-bottom: 1px solid lightgray;
    transition: all 0.2s ease-in-out;

    -webkit-appearance: none;
    border-radius: 0;
    font-size: 1rem;
  }

  textarea {
    font-size: 1rem;
  }

  .contact-form-input:focus,
  .contact-form-text-area:focus {
    background-color: goldenrod;
    color: var(--primary-blue);
  }

  .contact-form-input::-webkit-input-placeholder, .contact-form-text-area::-webkit-input-placeholder {
    color: whitesmoke;
  }

  .contact-form-input::placeholder,
  .contact-form-text-area::placeholder {
    color: whitesmoke;
  }

  /* .contact-form-input:valid,
  .contact-form-text-area:valid {
    background-color: goldenrod;
    color: var(--primary-blue);
  } */
  .contact-form-input:active,
  .contact-form-text-area:active {
    background-color: goldenrod;
    color: var(--primary-blue);
  }

  .contact-form-input:invalid,
  .contact-form-text-area:invalid {
    background-color: rgb(218, 64, 64);
    color: var(--primary-blue);
  }

  .contact-form-input,
  .contact-form-text-area {
    padding: 1rem;
    width: 100%;
    outline: none;
  }

  .contact-form-text-area {
    /* max-height: 40%; */
    height: 100%;
    overflow: auto;
    resize: none;
    background-color: rgba(255, 255, 255, 0.308);
    border: none;
    border-bottom: 1px solid lightgray;
    -webkit-appearance: none;
    border-radius: 0;
  }

  .contact-form-btn {
    padding: 1rem 0;

    color: whitesmoke;
    background-color: aliceblue;
    /* border: 1px solid lightgray; */
    border: none;
    border-radius: 0.25rem;

    cursor: pointer;
    width: 100%;
    /* margin-top: 2rem; */
    transition: all 0.2s ease-in-out;
    color: var(--primary-blue);
  }

  .contact-form-btn:disabled {
    background-color: lightgray;
    cursor: not-allowed;
  }

  .contact-form-btn:hover {
    color: var(--primary-blue);
    background-color: goldenrod;
  }

  .error-msg-container {
    /* grid-column: 1/3; */
  }

  .error-msg-container > p {
    color: rgb(219, 63, 63);
  }
}

@media (min-width: 1600px) {
  .contact-form-input,
  .contact-form-text-area {
    padding: 1rem;
  }
}

@media (max-width: 768px) {
  .contact-form {
    display: grid;
    grid-gap: 1rem;
    gap: 1rem;
    padding: 0 2rem;
  }

  .contact-form-label {
    color: lightgray !important;
    display: block;
    /* text-transform: uppercase; */
    /* letter-spacing: 2px; */
  }

  .contact-form-input {
    background-color: rgba(255, 255, 255, 0.308);
    border: none;
    border-bottom: 1px solid lightgray;
    transition: all 0.2s ease-in-out;
    font-size: 16px;
    -webkit-appearance: none;
    border-radius: 0;
    /* border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; */
  }

  .contact-form-input:focus,
  .contact-form-text-area:focus {
    background-color: goldenrod;
    color: var(--primary-blue);
  }

  .contact-form-input::-webkit-input-placeholder, .contact-form-text-area::-webkit-input-placeholder {
    color: whitesmoke;
  }

  .contact-form-input::placeholder,
  .contact-form-text-area::placeholder {
    color: whitesmoke;
  }

  /* .contact-form-input:valid,
    .contact-form-text-area:valid {
      background-color: goldenrod;
      color: var(--primary-blue);
    } */
  .contact-form-input:active,
  .contact-form-text-area:active {
    background-color: goldenrod;
    color: var(--primary-blue);
  }

  .contact-form-input:invalid,
  .contact-form-text-area:invalid {
    background-color: rgb(218, 64, 64);
    color: var(--primary-blue);
  }

  .contact-form-input,
  .contact-form-text-area {
    padding: 1rem;
    width: 100%;
    outline: none;
  }

  .contact-form-text-area {
    /* max-height: 40%; */
    height: 100%;
    overflow: auto;
    resize: none;
    background-color: rgba(255, 255, 255, 0.308);
    border: none;
    border-bottom: 1px solid lightgray;
    font-size: 1rem;
    -webkit-appearance: none;
    border-radius: 0;
    /* border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; */
  }

  .contact-form-btn {
    padding: 1rem 0;

    color: whitesmoke;
    background-color: aliceblue;
    /* border: 1px solid lightgray; */
    border: none;
    border-radius: 0.25rem;

    cursor: pointer;
    width: 100%;
    /* margin-top: 2rem; */
    transition: all 0.2s ease-in-out;
    color: var(--primary-blue);
    font-size: 1rem;
  }

  .contact-form-btn:hover {
    color: var(--primary-blue);
    background-color: goldenrod;
  }

  .error-msg-container {
    /* grid-column: 1/3; */
  }

  .error-msg-container > p {
    color: rgb(219, 63, 63);
  }
}

@media (min-width: 769px) {
  .privacy-section {
    padding: 10% 5%;
    background: rgb(244, 244, 244);
  }

  .privacy-header {
    font-family: var(--heading-text) !important;
  }

  .privacy-section strong {
    font-family: var(--primary-text) !important;
  }

  .privacy-header {
    font-size: 2rem;
  }

  .privacy-section h2 {
    font-size: 1rem;
    text-decoration: underline;
    font-family: var(--heading-text) !important;
  }

  .privacy-section > ul {
    padding-left: 1.5rem;
  }
  .privacy-section > ul > li {
    /* font-family: "Circular Std Book", sans-serif; */
  }

  address {
    font-style: normal;
  }
}

@media (max-width: 768px) {
  .privacy-section {
    padding: 15% 5%;
    background: rgb(244, 244, 244);
  }

  .privacy-header {
    font-family: var(--heading-text) !important;
  }

  .privacy-section strong {
    font-family: var(--primary-text) !important;
  }

  .privacy-section > *,
  a {
  }

  .privacy-header {
    font-size: 200%;
    font-family: var(--heading-text) !important;
  }

  .privacy-section h2,
  h3 {
    /* text-decoration: underline; */
    font-family: var(--heading-text) !important;
    font-size: 1rem;
  }

  .privacy-section > ul {
    padding-left: 1.5rem;
  }
  .privacy-section > ul > li {
  }

  address {
    font-style: normal;
  }
}

@media (max-width: 768px) {
  .regulatory-section {
    padding: 15% 5%;
    background: rgb(244, 244, 244);
  }

  .regulatory-section > h2,
  h3 {
    font-family: var(--heading-text) !important;
    font-size: 1rem;
  }

  .regulatory-header {
    font-size: 200%;
    font-family: var(--heading-text) !important;
  }

  .regulatory-section > ul {
    padding-left: 1.5rem;
  }

  .regulatory-section > ul > li {
  }

  table {
    width: 100%;
  }

  td {
    border-bottom: 1px solid lightgray;
  }
}

@media (min-width: 769px) {
  .regulatory-section {
    padding: 10% 5%;
    background: rgb(244, 244, 244);
  }

  .regulatory-section > h2 {
    font-size: 1rem !important;
    font-family: var(--heading-text) !important;
    text-decoration: underline;
  }

  strong {
    font-size: 1rem !important;
    font-family: var(--heading-text) !important;
    text-decoration: underline !important;
  }

  .regulatory-header {
    font-size: 200%;
    font-family: var(--heading-text) !important;
  }

  .regulatory-section > ul {
    padding-left: 1.5rem;
  }

  .regulatory-section > ul > li {
    /* font-family: "Circular Std Book"; */
  }

  table {
    width: 60%;
  }

  td {
    border-bottom: 1px solid lightgray;
  }
}

@media (min-width: 769px) {
  .services-section {
  }

  .services-bg-img {
    height: 100%;
    width: 100%;

    background-size: cover;
    background-position-y: 50%;
    padding: 15%;

    background-repeat: no-repeat;
  }

  .im-bg-img {
    background-size: cover;
    background-position: center;
    padding: 15%;
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
  }

  .services-article {
    color: whitesmoke;
    font-size: 125%;

    padding: 0% 10%;

    height: -webkit-max-content;

    height: max-content;
  }

  .page-sub-list {
    padding-left: 1rem;
    color: whitesmoke;
    line-height: 1.5;
  }

  h5 {
    font-family: var(--primary-text);
    color: white;
    font-size: 1rem;
  }

  .fp-img {
    object-fit: cover;
  }
}

@media (min-width: 1100px) {
  .fp-col-2 {
    width: 82%;
    margin: 0 auto;
  }
}

@media (min-width: 1600px) {
  .image-card-img {
    height: 200px;
  }

  .fp-image-card-grid {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 768px) {
  .services-section {
  }

  .page-sub-list {
    padding: 0 3rem;
    color: whitesmoke;
    line-height: 1.5;
  }

  h5 {
    font-family: var(--primary-text);
    color: white;
    font-size: 1rem;
    padding: 0 2rem;
  }

  .image-card-grid {
    display: grid;
    padding: 1rem;
    margin-top: 1rem;
    grid-gap: 2rem;
    gap: 2rem;
  }
}

@media (min-width: 769px) {
  .image-card-grid {
    display: grid;
    padding: 1rem 0;
    margin-top: 1rem;
    grid-gap: 1rem;
    gap: 1rem;
    /* grid-template-columns: 600px 600px; */
    grid-template-columns: 1fr;
    grid-template-columns: 1fr 1fr;
    /* grid-template-columns: repeat(auto-fill, minmax(1fr, 1fr)); */
    /* display: flex;
        flex-direction: row; */
    overflow-x: scroll !important;
    justify-items: center;
    justify-content: center;
    /* width: 125%; */
    position: relative;
  }

  .image-card-container {
    background-color: whitesmoke;

    height: 100%;
    width: 100%;

    display: flex !important;
    flex-direction: column;
    justify-content: flex-start !important;
    flex-basis: content;
    align-items: flex-start;
  }

  .image-card-img-cont {
    width: 100%;
  }

  .image-card-img {
    /* height: 100%; */
    flex: 1 1;
    /* height: 200px; */
    height: 10rem;
    width: 100%;
    object-fit: cover;
  }

  .image-card-title {
    text-transform: uppercase;
    letter-spacing: 1px;
    color: var(--primary-blue);
    font-size: 1rem !important;
    font-weight: 500;
    margin: 0;
    /* padding-top: 1rem; */
    font-family: var(--heading-text) !important;
  }

  .image-card-main {
    /* background-color: whitesmoke; */
    margin: 0.5rem 1rem;
    border-bottom: 1px solid lightgray;
    flex: 2 1;
  }

  .image-card-text {
    color: rgba(0, 0, 0, 0.596) !important;
    font-size: 1rem !important;
  }

  .image-card-link-cont {
    /* grid-column: 2/3; */
    /* padding: 0rem 1rem 1rem 1rem; */
    padding-bottom: 1rem;
    padding-right: 1rem;
    padding-left: 1rem;
    max-height: 100px;
  }

  .image-card-link {
    text-transform: uppercase;
    letter-spacing: 1px;
    color: var(--primary-blue);
    font-size: 0.85rem !important;
    font-size: 1rem !important;

    /* width: 100vw; */
    font-family: var(--heading-text) !important;
    text-decoration: none;

    /* margin-left: 1rem; */
  }

  .image-card-link:hover {
    color: var(--primary-gold);
  }

  .fp-image-card-grid {
    width: 100%;
    grid-template-columns: 1fr !important;
    justify-items: center !important;
  }

  .fp-img-card-container {
    /* width: 75%; */
  }
}
@media (max-width: 768px) {
  .image-card-container {
    display: grid;
    background-color: whitesmoke;
    grid-template-columns: 1fr;
    padding-bottom: 1rem;
    width: 100%;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    /* margin-bottom: 2rem; */
  }

  .image-card-img {
    height: 200px;
    width: 100%;
    object-fit: cover;
  }

  .image-card-title {
    text-transform: uppercase;
    letter-spacing: 1px;
    color: var(--primary-blue);
    font-size: 1rem !important;
    font-weight: 500;
    margin: 0;
    font-family: var(--heading-text) !important;
  }

  .image-card-main {
    /* background-color: whitesmoke; */
    margin: 0.5rem 1rem;
    border-bottom: 1px solid lightgray;
  }

  .image-card-text {
    color: rgba(0, 0, 0, 0.596) !important;
    font-size: 1rem !important;
  }

  .image-card-link-cont {
    /* grid-column: 2/3; */
    /* padding: 0rem 1rem 1rem 1rem; */
    padding-bottom: 0rem;
    padding-left: 1rem;
    padding-right: 1rem;
    max-height: 100px;
  }

  .image-card-link {
    text-transform: uppercase;
    letter-spacing: 1px;
    color: var(--primary-blue);
    font-size: 0.75rem !important;

    /* width: 100vw; */
    font-family: var(--heading-text) !important;
    text-decoration: none;
    font-weight: 500;
    text-decoration: underline;
  }
}

@media (min-width: 768px) {
  .cookies-notification {
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    background: whitesmoke;
    text-align: center;

    border-top-right-radius: 1rem;
    border-top-left-radius: 1rem;
    z-index: 999;
    /* border-bottom-right-radius: 1rem; */
  }

  .cookies-notification > button {
    /* margin: 5%; */
    padding: 0.5rem 2rem;
    margin-bottom: 1rem;
    background: rgb(0, 145, 255);
    border: none;
    color: whitesmoke;
    cursor: pointer;
  }
}
@media (max-width: 768px) {
  .cookies-notification {
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    background: whitesmoke;
    border-top-right-radius: 1rem;
    border-top-left-radius: 1rem;
    text-align: center;
    z-index: 9999;
  }

  .cookies-notification > button {
    margin-bottom: 5%;
    padding: 2.5% 5%;

    background: rgb(0, 145, 255);
    border: none;
    color: whitesmoke;
    cursor: pointer;
  }
}

@media (min-width: 769px) {
  .careers-section {
  }

  .careers-bg-img {
    background: url("https://storage.googleapis.com/throgmortoncm-files/main-images/careers-min.jpg");
    height: 100%;
    width: 100%;

    background-size: cover;
    background-position-y: 0%;
    background-position-x: 50%;
    padding: 15%;

    background-repeat: no-repeat;
  }

  .careers-article {
    color: whitesmoke;
    font-size: 125%;

    padding: 15% 5%;

    height: -webkit-max-content;

    height: max-content;
  }

  .recruitment-link {
    color: var(--primary-gold) !important;
  }

  .recruitment-link:hover {
    color: gold;
  }

  .careers-img {
    object-fit: cover;
  }
}

@media (max-width: 768px) {
  .careers-section {
  }

  .careers-bg-img {
    background: url("https://storage.googleapis.com/throgmortoncm-files/main-images/careers-min.jpg");
    height: 30vh;
    width: 100%;

    background-size: cover;
    background-position-y: 25%;
    padding: 0 5%;
    display: grid;
    align-items: end;

    background-repeat: no-repeat;
  }

  .careers-article {
    color: whitesmoke;
    font-size: 125%;

    padding: 5%;

    height: -webkit-max-content;

    height: max-content;
    border-top: 1px solid #c2a044;
  }
  .recruitment-link {
    color: #c2a044 !important;
  }
}

@media (min-width: 769px) {
  .downloads-section {
  }

  .downloads-content {
    min-height: 50vh;
    padding: 15% 10%;
    max-height: 100vh;
    overflow: auto;
  }

  .downloads-category {
    padding: 5% 5% 2.5% 5%;
  }

  .downloads-btn {
    font-size: 150%;
    background: none;
    border: none;
    width: 100%;
    text-align: left;
    border-bottom: 1px solid lightgray;
    cursor: pointer;
    outline: none;
    /* font-family: "Circular Std Book", sans-serif; */
    color: #c2a044;
  }

  .downloads-btn:hover {
    color: gold;
  }

  .download-links {
    transition: all 0.2s ease;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    background: none;
  }

  .date-header {
    color: lightgray;
    padding-bottom: 1%;
    border-bottom: 1px solid gold;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-size: 125%;
    /* font-family: "Circular Std Book", sans-serif; */
  }

  .download-link {
    color: lightgray;
    font-size: 100%;
    padding: 1%;
    text-decoration: underline !important;
    /* font-family: "Circular Std Book", sans-serif; */
  }

  .download-link:hover {
    color: whitesmoke;
  }

  .Collapsible {
    margin: 5% 0;
  }

  .expand-btn {
    float: right;
  }

  .subscribe-disclaimer-text {
    grid-column: 1/3;
  }

  .sign-up-text {
    font-size: 100% !important;
    color: whitesmoke;
  }

  /* .downloads-col {
    padding: 10% 6rem;
  } */

  .pdfviewer-container {
    position: absolute;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    z-index: 9999;
    padding: 5rem 10rem;
    padding-top: 2rem;
    background-color: rgba(255, 255, 255, 0.6);
  }

  iframe.pdfviewer {
    height: 100%;
    width: 100%;
  }

  svg.close {
    size: 3rem;
    float: right;
    height: 3rem;
    width: 3rem;
    cursor: pointer;
  }

  svg.close > path {
    fill: var(--primary-blue);
    stroke: var(--primary-blue);
  }

  .subscribe-modal-btn {
    padding: 0.5rem;
    /* font-family: "Circular Std Book", sans-serif; */
    width: -webkit-min-content;
    width: min-content;
    font-size: 1rem;
    background: #164382;
    color: #c2a044;
    border: none;
    border-radius: 0.25rem;
    cursor: pointer;
    max-width: 100px;
    background: white;
  }

  .subscribe-modal-btn:hover {
    background-color: whitesmoke;
  }

  #mc_embed_signup {
    border-radius: 0.25rem;
    padding: 1rem;
  }

  #mc_embed_signup * {
    /* font-family: "Circular Std Book", sans-serif; */
  }

  .subscribe-header {
    font-size: 150%;

    font-weight: 400;
    color: #164382;
    /* font-family: "Circular Std Book", sans-serif; */
    /* color: whitesmoke; */
    margin-top: 0;
    color: #c2a044;
  }

  .subscribe-form-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
    gap: 1rem;
  }

  .subscribe-input {
    padding: 1rem;
    font-size: 1rem;
    border: none;
    /* border-radius: 0.25rem 0.25rem 0 0; */
    border-radius: 0;
    border-bottom: 3px solid #c2a044;
    background: rgba(92, 102, 142, 0.694) !important;
    transition: all 0.2s ease;
    outline: none !important;
    color: whitesmoke;
  }

  .subscribe-input:valid {
    background: #c2a044 !important;
    border-bottom-color: #164382;
  }

  .subscribe-input::-webkit-input-placeholder {
    color: white;
  }

  .subscribe-input::placeholder {
    color: white;
  }

  .subscribe-input:focus {
    background: #c2a044 !important;
    border-bottom-color: #164382;
  }

  input:-internal-autofill-selected {
    background: red !important;
  }

  .subscribe-btn {
    padding: 1rem;
    font-size: 1rem;
    border-radius: 0.25rem;
    background: #164382;
    border: none;
    transition: all 0.2s ease;
    outline: none !important;
    color: whitesmoke;
    cursor: pointer;
  }

  .subscribe-btn:active {
    background: #c2a044;
    color: whitesmoke;
  }

  .subscribe-btn:disabled {
    background: #1643826f;
    cursor: not-allowed;
    color: rgb(240, 235, 235);
  }

  .subscribe-status-container {
    padding: 1rem;
    border-radius: 0.25rem;
  }

  .error {
    background: rgb(227, 66, 66);
    color: whitesmoke;
    grid-column: 1/3;
  }

  .success {
    background: rgb(80, 213, 106);
    color: whitesmoke;
    grid-column: 1/3;
  }

  .close-subscribe-modal-btn {
    position: absolute;
    right: 0rem;
    top: 0rem;
    padding: 0.25rem 0.5rem;
    border: none;
    font-size: 1rem;
    background: none;
    cursor: pointer;
  }
}

.subscribe-disclaimer-text {
  color: rgb(174, 174, 174);
  font-size: 75% !important;
}

/* @media (min-width: 1600px) {
  .downloads-col {
    padding: 10% 14rem;
  }
} */

@media (max-width: 768px) {
  .downloads-section {
    /* border-bottom: 1px solid #c2a044; */
  }

  .downloads-bg-img {
    background: url("https://images.unsplash.com/photo-1572549113071-c90a8f3c26a5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2000&q=80");
    background: url("https://storage.googleapis.com/throgmortoncm-files/main-images/downloads-min.jpg");
    height: 30vh;
    width: 100%;

    background-size: 150%;
    background-position-y: 40%;
    background-position-x: 0% !important;

    padding: 25% 5%;
    padding: 0 5%;
    display: grid;
    align-items: end;

    background-repeat: no-repeat;
  }

  .downloads-content {
    min-height: 50vh;
    padding: 5%;
    border-top: 1px solid #c2a044;
  }

  .downloads-category {
    padding: 5% 5% 5% 5%;
  }

  .downloads-btn {
    font-size: 150%;
    background: none;
    border: none;
    color: gold;
    width: 100%;
    text-align: left;
    border-bottom: 1px solid lightgray;
    cursor: pointer;
    outline: none;
    font-family: "Circular Std Book";
    color: #c2a044;
  }

  .download-links {
    transition: all 0.2s ease;

    overflow: hidden;
    display: flex;
    flex-direction: column;

    background: none;
    justify-content: center;
  }

  .date-header {
    color: lightgray;
    padding-bottom: 1rem;
    padding: 0;
    margin: 0;
    margin-top: 1rem;
    border-bottom: 1px solid gold;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-size: 1rem;
    font-family: var(--heading-text);
  }

  .download-link {
    color: whitesmoke;

    padding: 1rem 1rem;
  }

  .expand-btn {
    float: right;
  }

  .sign-up-text {
    font-size: 100% !important;
    color: whitesmoke;
  }
  .Collapsible {
    margin: 0rem 0 !important;
    padding-bottom: 2rem;
  }

  .downloads-col {
    padding: 2rem 2rem;
  }

  /* iframe {
    height: 90vh;
  } */

  .pdfviewer-container {
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0rem;
    left: 0;
    z-index: 9999;
    padding: 0rem 0rem;
    padding-top: 2rem;
    background-color: black;
  }

  iframe.pdfviewer {
    height: 100%;
    width: 100%;
  }

  svg.close {
    /* size: 3rem; */
    float: right;
    height: 2rem;
    width: 2rem;
    cursor: pointer;
    margin-right: 1rem;
  }

  svg.close > path {
    fill: var(--primary-blue);
    stroke: var(--primary-blue);
  }

  .subscribe-input {
    padding: 1rem;
    font-size: 1rem;
    border: none;
    /* border-radius: 0.25rem 0.25rem 0 0; */
    border-radius: 0;
    border-bottom: 3px solid #c2a044;
    background: rgba(92, 102, 142, 0.694) !important;
    transition: all 0.2s ease;
    outline: none !important;
    color: whitesmoke;
  }

  .subscribe-input:valid {
    background: #c2a044 !important;
    border-bottom-color: #164382;
  }

  .subscribe-input::-webkit-input-placeholder {
    color: white;
  }

  .subscribe-input::placeholder {
    color: white;
  }

  .subscribe-input:focus {
    background: #c2a044 !important;
    border-bottom-color: #164382;
  }

  input:-internal-autofill-selected {
    background: red !important;
  }

  .subscribe-btn {
    padding: 1rem;
    font-size: 1rem;
    border-radius: 0.25rem;
    background: #164382;
    border: none;
    transition: all 0.2s ease;
    outline: none !important;
    color: whitesmoke;
    cursor: pointer;
  }

  .subscribe-btn:active {
    background: #c2a044;
    color: whitesmoke;
  }

  .subscribe-btn:disabled {
    background: #1643826f;
    cursor: not-allowed;
    color: rgb(240, 235, 235);
  }

  .subscribe-status-container {
    padding: 1rem;
    border-radius: 0.25rem;
  }

  .error {
    background: rgb(227, 66, 66);
    color: whitesmoke;
    grid-column: 1/3;
  }

  .success {
    background: rgb(80, 213, 106);
    color: whitesmoke;
    grid-column: 1/3;
  }

  .close-subscribe-modal-btn {
    position: absolute;
    right: 0rem;
    top: 0rem;
    padding: 0.25rem 0.5rem;
    border: none;
    font-size: 1rem;
    background: none;
    cursor: pointer;
  }

  .subscribe-modal-btn {
    padding: 0.5rem;
    /* font-family: "Circular Std Book", sans-serif; */
    width: -webkit-min-content;
    width: min-content;
    font-size: 1rem;
    background: #164382;
    color: #c2a044;
    border: none;
    border-radius: 0.25rem;
    cursor: pointer;
    max-width: 100px;
    background: white;
  }

  .subscribe-header {
    font-size: 150%;

    font-weight: 400;
    color: #164382;
    /* font-family: "Circular Std Book", sans-serif; */
    /* color: whitesmoke; */
    margin-top: 0;
    color: #c2a044;
    padding: 1rem;
    margin-bottom: 0;
  }

  .subscribe-form-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1rem;
    gap: 1rem;
    padding: 1rem;
  }
}

@media (min-width: 769px) {
  .best-execution-section {
    padding: 10% 5%;
    background: rgb(244, 244, 244);
  }

  .best-execution-section > *,
  .best-execution-section a {
    font-family: "Circular Std Book";
  }

  .best-execution-header {
    font-size: 200%;
    font-family: var(--heading-text) !important;
  }

  strong {
    font-size: 1rem;
    font-family: var(--heading-text) !important;
    text-decoration: underline !important;
  }

  .best-execution-section > h2 {
    text-decoration: underline;
  }

  .best-execution-section > ul {
    padding-left: 1.5rem;
  }
  .best-execution-section > ul > li {
    font-family: "Circular Std Book";
  }
}

@media (max-width: 768px) {
  .best-execution-section {
    padding: 15% 5%;
    background: rgb(244, 244, 244);
  }

  .best-execution-section > *,
  .best-execution-section a,
  .best-exection-section p {
    font-family: "Circular Std Book", sans-serif;
  }

  .best-execution-header {
    font-size: 200%;
    font-family: var(--heading-text) !important;
  }

  strong {
    font-family: var(--heading-text) !important;
  }

  .best-execution-section > h2 {
    text-decoration: underline;
  }

  .best-execution-section > ul {
    padding-left: 1.5rem;
  }
  .best-execution-section > ul > li {
    font-family: "Circular Std Book", sans-serif;
  }
}

@media (min-width: 769px) {
  .team-main-cont {
    display: grid;
    grid-template-columns: 1fr 3fr;
    /* padding: 0 10%; */
    /* margin-left: 1rem; */
    align-items: center;
    /* justify-items: center; */
    /* height: 375px; */
    padding: 1rem 0;
    /* border-left: 1px solid lightslategray; */
  }

  .team-user-select-cont {
    display: grid;
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;

    max-height: 60vh;
    overflow-y: auto;
    padding-right: 2rem;

    &::-webkit-scrollbar {
      width: 0.25rem;
      display: block;

    }

    &::-webkit-scrollbar-thumb {
      background-color:  rgba(138, 138, 138, 0.638);;
      outline: none;
      border-radius: 1rem;
    }

    & div {
      display: flex;
      flex-direction: row;
      align-items: center;
      grid-gap: 1rem;
      gap: 1rem;
    }
  }

  .team-select-btn {
    border: none;
    cursor: pointer;
    background-color: transparent;
    outline: none;
    position: relative;
    text-align: left;
    font-size: 1.1rem;
    color: whitesmoke;
    /* margin-left: 1rem;
    padding-left: 2rem;
    width: 150px; */
  }

  .team-select-btn::after {
    content: "";
    height: 1px;
    width: 0%;

    position: absolute;

    border-bottom: 1px solid goldenrod;
    left: 0%;
    bottom: 0;
  }

  .team-select-btn:hover::after {
    -webkit-animation: extend 0.2s linear;
            animation: extend 0.2s linear;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
  }

  @-webkit-keyframes extend {
    from {
      width: 0%;
    }
    to {
      width: 100%;
    }
  }

  @keyframes extend {
    from {
      width: 0%;
    }
    to {
      width: 100%;
    }
  }

  .our-team-article {
    color: whitesmoke;
    margin: 0rem 2.5rem;
    padding-left: 1.25rem;
    border-left: 1px solid white;
  }

  .our-team-article > h1 {
    margin-top: 0;
  }

  .team-bg-img {
    height: 100%;
    width: 100%;
    position: relative;

    background-size: cover;
    background-position-y: 0%;
    background-position-x: 50%;
    /* padding: 15%; */

    background-repeat: no-repeat;
  }

  .team-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    /* max-height: 50vh;s */
    overflow: auto;
    grid-row-gap: 1rem;
    row-gap: 1rem;
    max-height: 650px;
  }

  .team-grid::-webkit-scrollbar {
    width: 5px;
    display: none;
  }

  /* Track */
  .team-grid::-webkit-scrollbar-track {
    background: #f1f1f1db;
  }

  /* Handle */
  .team-grid::-webkit-scrollbar-thumb {
    background: #c2a044;
  }

  /* Handle on hover */
  .team-grid::-webkit-scrollbar-thumb:hover {
    background: #ae8410;
  }

  .team-card {
    display: grid;
    grid-template-columns: 1fr;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    /* justify-items: center; */
    cursor: pointer;
  }

  .team-card-img {
    height: 225px;
    width: 225px;
    background-color: white;
    object-fit: contain;
  }

  .team-card-details {
    padding: 0.5rem 0;

    display: grid;
    max-height: 266px;
  }

  .team-card-details-modal {
    padding: 0.5rem;

    display: grid;
  }

  .team-member-title-modal-mob {
    display: none;
  }

  .team-card-name {
    font-size: 1.25rem;
    color: whitesmoke;
    padding-bottom: 0.5rem;

    margin: 0;

    font-weight: lighter;
  }

  .team-card-role {
    font-size: 1rem;
    margin: 0;
    color: rgb(210, 210, 210);
    color: rgb(138, 138, 138);
    padding: 0.5rem 0;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: lighter;
  }

  .team-member-modal {
    display: grid;
    grid-template-columns: auto 1fr;
  }

  .team-bio {
    color: rgb(145, 144, 144);
  }

  .modal-navigation-container {
    justify-self: end;
  }

  .modal-navigation-container > button {
    font-size: 1rem;
    color: #c2a044;
    background: none;
    border: none;
    padding: 0 1rem;

    cursor: pointer;
  }

  .close-modal-btn {
    position: absolute;
    right: 0rem;
    padding: 0.25rem 0.5rem;
    border: none;
    font-size: 1rem;
    background: none;
    cursor: pointer;
  }

  .ReactModal__Overlay--after-open {
    background-color: rgba(0, 0, 0, 0.687) !important;
  }

  .bio-links-cont {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .bio-link {
    color: #c2a044;
    /* color: rgb(17, 65, 129); */
    margin-right: 1rem;
  }

  .bio-link:hover {
    color: goldenrod;
  }

  .bio-link > img {
    height: 1.25rem;
    width: 1.25rem;
    vertical-align: middle;
  }

  .active-team {
    transition: all 0.2s ease-in;
    color: var(--primary-gold);
  }

  .team-img {
    /* object-fit: cover; */
    width: 250px;
    height: 300px;
    /* filter: brightness(0.7); */
  }

  .team-col-1 {
    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: center;
  }

  .team-links-cont {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .team-email-link {
    text-decoration: none;
    color: var(--primary-gold);
    font-size: 1.25rem;
  }
}

@media (min-width: 1600px) {

  .team-select-btn {
    font-size: 1rem;
    /* margin-left: 1rem;
    padding-left: 2rem;
    width: 150px; */
  }
}

@media (max-width: 768px) {
  .our-team-article {
    color: whitesmoke;

    padding: 5% 2rem;

    /* height: max-content; */
    /* border-top: 1px solid #c2a044; */
    overflow: hidden;
  }

  .team-bg-img {
    background: url("https://storage.googleapis.com/throgmortoncm-files/main-images/our-team-min.jpg");
    height: 30vh;
    width: 100%;

    background-size: cover;
    background-position-y: 25%;
    padding: 0 5%;
    display: grid;
    align-items: end;

    background-repeat: no-repeat;
  }

  .team-grid {
    display: grid;
    grid-template-columns: 1fr;

    overflow: auto;
    grid-row-gap: 1rem;
    row-gap: 1rem;
    overflow: hidden;
  }

  .team-card {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;

    cursor: pointer;
    grid-column-gap: 1rem;
    -webkit-column-gap: 1rem;
            column-gap: 1rem;
  }

  .team-card-details {
    padding: 0.5rem;

    display: grid;
  }

  .team-card-details-modal {
    padding: 0.5rem;

    display: grid;
    padding: 1rem;
    grid-column: 1/3;
  }

  .team-card-img {
    height: 150px;
    width: 150px;
    background-color: white;
  }
  .team-img-alt {
    height: 150px;
    width: 150px;
  }

  .team-card-name {
    font-size: 1.25rem;
    color: whitesmoke;

    margin: 0;
    padding: 0.5rem 0;
    /* color: var(--primary-gold); */
    font-weight: lighter;
  }

  .team-card-role {
    font-size: 75%;
    margin: 0;
    color: rgb(210, 210, 210);
    color: rgb(154, 154, 154);
    padding: 0.5rem 0;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: lighter;
  }

  .team-member-modal {
    display: grid;
    grid-template-columns: 1fr 1fr;
    /* column-gap: 1rem; */
  }

  .team-member-title-modal-desk {
    display: none;
  }
  .team-member-title-modal-mob {
    align-self: center;
    padding: 1rem;
  }

  .team-bio {
    color: rgb(133, 131, 131);
    grid-column: 1/3;
  }

  .ReactModal__Overlay--after-open {
    background-color: rgba(0, 0, 0, 0.687) !important;
  }

  .bio-links-cont {
    display: grid;
    flex-direction: row;
    align-items: center;
    grid-gap: 1rem;
    gap: 1rem;
  }

  .bio-link {
    color: rgb(17, 65, 129);
    color: #c2a044;
    margin-right: 1rem;
  }

  .bio-link > img {
    height: 1.45rem;
    width: 1.45rem;
  }

  .bio-links-cont-desk {
    display: none;
  }

  /* @media (max-height: 560px) {
    .team-bio {
      max-height: 220px;
      overflow-y: scroll;
    }
  } */

  .team-select-btn {
    border: none;
    cursor: pointer;
    background-color: transparent;
    outline: none;
    position: relative;
    text-align: left;
    font-size: 1rem;
    color: whitesmoke;
    padding: 0.5rem 1rem;

    /* margin-left: 1rem;
    padding-left: 2rem;
    width: 150px; */
  }

  .team-user-select-cont {
    display: flex;
    flex-direction: row;
    grid-gap: 1rem;
    gap: 1rem;

    overflow-x: scroll;
    box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.342);
  }

  .active-team {
    transition: all 0.2s ease-in;
    color: var(--primary-gold);
  }

  /* .team-col-1 {
    background-color: grey !important;
  } */

  /* .team-img {
    filter: none;
    height: 40vh;
    width: 100%;
    margin-top: 10vh;
    object-fit: contain;

    z-index: -1;
  } */

  .team-img {
    object-position: top;
    /* filter: none; */
  }

  .team-links-cont {
    display: flex;
    flex-direction: row;
  }

  .team-email-link {
    text-decoration: none;
    color: var(--primary-gold);
    font-size: 1.25rem;
    font-weight: lighter;
  }

  .team-col-2 {
    padding-top: 0;
  }
}

@media (min-width: 769px) {
  .menu-container {
    width: calc(50 * 1rem) !important;
    position: fixed;
    min-height: 100vh;
    background-color: var(--primary-blue);
    right: 0;
    z-index: 100;
    display: grid;
    grid-template-columns: 1fr 1fr;
    transition: all 1s ease-in-out !important;
    /* align-items: top; */

    padding: 3rem 0rem 3rem 5rem;
    /* border: 20px silver; */
    box-sizing: border-box;
    align-items: start;

    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.466);
  }

  .menu-content-col {
    height: 100%;
    display: grid;
    align-items: stretch;
    align-content: start;
    grid-gap: 1rem;
    gap: 1rem;
    justify-items: left;
    position: relative;
    z-index: 101;
    padding-bottom: 1rem;
  }

  .menu-address-text {
    margin-left: 0 !important;
  }

  .menu-header {
    position: absolute;
    font-size: 7rem;
    color: rgba(0, 0, 0, 0.13);
    top: 0;
    margin: 0;
  }

  .menu-subheading {
    color: lightgray;
    font-weight: 400;
    letter-spacing: 3px;
    text-transform: uppercase;
  }

  .menu-footer {
    grid-column: 1/3;
  }

  .menu-footer-link-container {
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    grid-gap: 1rem;
    gap: 1rem;
    align-items: center;
    padding: 1rem 0;
    width: 75%;
    color: whitesmoke;
  }

  .header-menu {
    display: none;
  }

  .header-link {
    /* margin-right: 1.5vw; */
    font-weight: 400;
    text-decoration: none;
    background: none;
    border: none;
    /* font-family: "Playfair Display", serif; */
    font-size: 1.5rem;
    outline: none;
    border-bottom: 1px solid transparent;
    cursor: pointer;

    /* text-transform: uppercase; */
    /* letter-spacing: 2px; */

    color: #c2a044;

    transition: all 0.2s ease-in;
    color: rgba(245, 245, 245, 0.89);
  }

  .header-link::after {
    content: "";
    position: absolute;
    width: 0%;
    height: 1px;
    background-color: var(--primary-gold);
    left: 0;
    bottom: 0;
  }

  .header-link:hover {
    /* border-bottom: 1px solid gold; */

    color: var(--primary-gold);
  }

  .header-link:hover::after {
    -webkit-animation: extend 0.2s linear;
            animation: extend 0.2s linear;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
  }
  .header-link:focus::after {
    background-color: var(--primary-blue);
  }

  .throg-footer-link {
    margin: 0rem;
    font-size: 0.85rem;
    font-weight: 400;
    color: whitesmoke !important;
    text-decoration: none;
    /* margin-right: 1rem; */

    display: block;
    font-family: var(--primary-text);
  }

  .throg-footer-link:hover {
    color: var(--primary-gold) !important;
  }

  .linkedin-link {
    margin-top: 1rem;
  }

  .disclosure-text-container {
    display: grid;
    grid-gap: 1rem;
    gap: 1rem;
    margin-right: 8rem;
  }

  .disclosure-text {
    color: rgb(184, 182, 182);
    margin: 0;
    font-size: 75% !important;
  }

  .menu-linkedin-img {
    height: 1.5rem;
    width: 1.5rem;
  }

  .linkedin-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    display: grid;
    grid-template-columns: 1fr;
  }

  .linkedin-follow {
    margin-top: 0.1rem;
  }

  .menu-address {

    grid-column: 1/3;
  }

  .locations-header {
    color: lightgray;
    /* text-decoration: underline; */
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 0;
    /* grid-column: 1/3; */
    font-weight: 400;
  }

  .address-container {
    display: flex;
    flex-direction: row;
    grid-gap: 3rem;
    gap: 3rem;
    padding: 1rem 0;

  }
}

@media (min-width: 1400px) {
  .menu-container {
    width: 50vw;
    padding-left: 5rem;
  }
}

@media (min-width: 2000px) {
  .menu-container {
    width: 55vw;
    padding-left: 5rem;
  }
}

@media (max-width: 768px) {
  .menu-container {
    width: 100vw;
    position: fixed;
    height: 100vh;
    overflow-y: auto;
    background-color: var(--primary-blue);
    right: 0;
    z-index: 300;
    display: grid;
    grid-template-columns: 1fr;
    /* transition: all 0.2s ease-in-out; */
    transition: all 0.8s ease-in-out;

    box-sizing: border-box;
    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.466);
    padding: 1rem;
    padding-top: 0;

    /* padding-top: 5rem; */
  }

  .menu-content-col {
    height: 100%;
    display: grid;
    align-items: center;
    align-content: center;
    grid-gap: 1rem;
    gap: 1rem;
    justify-items: left;
    position: relative;
    z-index: 101;
    padding: 1rem;
    /* padding-bottom: 1rem; */
    border-bottom: 1px solid lightgray;
  }

  .menu-mob-col-1 {
    padding-top: 0;
    padding-bottom: 0;
    grid-gap: 0rem;
    gap: 0rem;
  }
  /* .menu-mob-col-1 > div {
    margin-bottom: 1rem;
  } */

  .link-cont-mob {
    margin-bottom: 1rem;
  }

  .menu-address-text {
    margin-left: 0 !important;
  }

  .menu-header {
    position: absolute;
    font-size: 7rem;
    color: rgba(0, 0, 0, 0.13);
    top: 0;
    margin: 0;
  }

  .menu-subheading {
    color: lightgray;
    font-weight: 400;
    letter-spacing: 3px;
    text-transform: uppercase;
    margin-top: 0;
  }

  .menu-footer {
    /* grid-column: 1/3; */
  }

  .menu-footer-link-container {
    display: grid;
    /* flex-direction: column; */
    justify-content: space-between;
    align-items: flex-start;
    padding: 1rem 0;
    grid-gap: 1rem;
    gap: 1rem;
    /* width: 88%; */
    color: whitesmoke;
    padding: 1rem;
  }

  .bullet {
    display: none;
  }

  .header-menu {
    position: relative;
    right: 0.5rem;
    top: 0rem;
  }

  .disclosure-text-container {
    padding: 1rem;
    padding-bottom: 5rem;
  }

  .disclosure-text {
    color: rgb(184, 182, 182);
    font-size: 75% !important;
    margin-top: 0;
  }

  .menu-linkedin-img {
    height: 1.5rem;
    width: 1.5rem;
    margin-top: 1rem;
  }

  .linkedin-link {
    /* margin-top: 1rem; */
  }

  .throg-footer-link {
    margin: 0rem;
    font-size: 0.85rem;
    font-weight: 400;
    color: whitesmoke !important;
    text-decoration: none;
    /* margin-right: 1rem; */

    display: block;
    font-family: var(--primary-text);
  }

  .linkedin-container {
    display: grid;
    grid-gap: 1rem;
    gap: 1rem;
  }

  .menu-address {
    /* margin-top: 1rem; */
    /* grid-row: 3/4;
    display: grid;
    gap: 1rem; */
    /* padding: 1rem; */
  }

  .locations-header {
    display: none;
  }

  .address-container {
    display: flex;
    flex-direction: row;
    grid-gap: 3rem;
    gap: 3rem;
    padding: 1rem;
    flex-wrap: wrap;
  }
}

@media (min-width: 769px) {
  .client-login-container {
    position: fixed;
    bottom: 1rem;
    /* background-color: rgb(245, 245, 245); */
    /* width: 100vw; */
    right: 0;
    padding: 1rem 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    z-index: 999;
  }

  .client-login-link {
    background-color: var(--primary-blue);
    color: whitesmoke;
    padding: 1rem;
    /* border: 1px solid lightgray; */
    box-shadow: 0px 2px 5px 1px rgba(17, 30, 43, 0.473);
    border-radius: 0.5rem;
    margin: 1rem;
    text-decoration: none;
  }

  .client-login-link:hover {
    background-color: goldenrod;
    color: var(--primary-blue);
  }

  .client-login-co-title {
    padding: 0;
    margin: 0;
    padding-left: 1rem;
  }
}

@media (max-width: 769px) {
  .client-login-container {
    position: fixed;
    bottom: 1rem;
    /* background-color: rgb(245, 245, 245); */
    /* width: 100vw; */
    right: 0;
    padding: 1rem 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    z-index: 202;
  }

  .client-login-link {
    background-color: var(--primary-blue);
    color: whitesmoke;
    padding: 1rem;
    /* border: 1px solid lightgray; */
    box-shadow: 0px 2px 5px 1px rgba(17, 30, 43, 0.473);
    border-radius: 0.5rem;
    margin: 1rem;
    font-size: 1rem;
    text-decoration: none;
  }

  .client-login-link:hover {
    background-color: goldenrod;
    color: var(--primary-blue);
  }

  .client-login-co-title {
    padding: 0;
    margin: 0;
    padding-left: 1rem;
  }
}

@media (min-width: 769px) {
  .hero-section {
    width: 100%;
    /* padding: 5% 2.5%; */
    height: 100vh;
    padding-top: 0%;
    overflow: hidden;

    display: grid;
    background-repeat: no-repeat;
    justify-items: right;
    overflow: auto;

    will-change: background-image;
    /* background: url("../media/home-min.jpg"); */
    background: url("https://storage.googleapis.com/throgmortoncm-files/main-images/home-img-min.jpg");

    /* display: grid; */
    background-size: cover;
    background-position: center;
    /* align-items: center; */
    background-repeat: no-repeat;
    /* background-position: -300px -00px;
    background-size: 150%; */
    scroll-snap-align: start;
    position: relative;
  }

  .hero-content-container {
    display: grid;
    /* grid-template-columns: 1fr 1fr; */
    grid-template-columns: 1fr;
    align-items: center !important;
    justify-items: right;
    background-color: rgba(0, 0, 0, 0.151);
    align-content: center;
    /* padding: 0 1rem; */

    width: 100%;
    height: 100%;
  }

  .hero-text-container {
    width: 40%;
    margin-bottom: 1rem;

    background-color: rgba(17, 66, 129, 0.612);

    padding: 0 2rem;
  }

  .hero-span {
    font-weight: 400;
  }

  .hero-sub-header {
    font-size: 1rem;
    text-align: left;
    font-weight: 400 !important;
    color: #164382;
    color: rgb(17, 65, 129);
    color: white;
  }

  @supports (-ms-ime-align: auto) {
    .hero-content-container {
      padding: 5%;
      width: 100%;
    }
  }

  .welcome-span {
    display: block;
    color: lightgray;
    margin-bottom: 0.5rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 1rem;
    /* font-weight: bold; */
  }

  .hero-title {
    /* font-weight: bold; */
    text-transform: none;
    font-size: 1.5rem;
    letter-spacing: 0px;
    /* text-transform: uppercase; */
    font-family: var(--heading-text) !important;
    line-height: 1.5;
    color: whitesmoke;
    display: block;
    padding-bottom: 1rem;
  }
}

@media (min-width: 1600px) {
  .hero-title {
    font-size: 1.5rem;
  }

  .hero-sub-header {
    font-size: 1.25rem;
  }
}

@media (min-width: 1300px) and (max-width: 1600px) {
  .hero-sub-header {
    padding-right: 1rem;
  }
}

@media (max-width: 768px) {
  .hero-section {
    /* width: 100%; */
    width: 100%;
    height: 100vh;

    /* padding: 5%; */

    /* background: url("../media/home-min.jpg"); */
    background: url("https://storage.googleapis.com/throgmortoncm-files/main-images/home-img-min.jpg");

    display: grid;
    background-size: cover;
    background-repeat: no-repeat;
    align-items: center;

    background-size: cover;

    /* align-items: center; */
    background-repeat: no-repeat;
    /* background-position: -1250px -200px; */
    background-position: -1150px -200px;
    /* background-position: 100% 25%; */
    background-size: 450%;
    /* background-size: 200%; */
    border-bottom: 1px solid #c2a044;
    -webkit-animation-name: imageMove;
            animation-name: imageMove;
    -webkit-animation-delay: 0ms;
            animation-delay: 0ms;
    -webkit-animation-duration: 5000ms;
            animation-duration: 5000ms;
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;
    -webkit-animation-direction: forwards;
            animation-direction: forwards;
  }

  @-webkit-keyframes imageMove {
    0% {
      /* filter: blur(5px); */
      background-position: -850px -200px;
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    100% {
      /* filter: blur(0px); */
      background-position: -1150px -200px;
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }

  @keyframes imageMove {
    0% {
      /* filter: blur(5px); */
      background-position: -850px -200px;
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    100% {
      /* filter: blur(0px); */
      background-position: -1150px -200px;
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  /* @keyframes imageMove {
    from {
      transform: translateX(0px);
    }
    to {
      transform: translateX(-300px);
    }
  } */

  .hero-text-container {
    padding-right: 1rem;
  }

  .hero-content-container {
    /* padding: 1rem; */

    height: -webkit-max-content;

    height: max-content;

    /* margin-bottom: 1rem; */
    display: grid;
    grid-template-columns: 1fr;
    align-items: center !important;
    justify-items: left;
    background-color: rgba(0, 0, 0, 0.363);
    /* background-color: rgba(0, 0, 0, 0.253); */
    /* display: grid;
    background-color: rgba(17, 66, 129, 0.612); */
    width: 100%;
    height: 100%;
    /* padding: 1rem; */
    /* margin-top: 33%; */
  }

  .hero-span {
    font-weight: 400;
  }

  .hero-sub-header {
    font-size: 1.25rem;
    text-align: left;
    font-weight: 400;
    color: #164382;
    color: white;
    /* padding: 1rem; */
    padding-left: 1rem;
  }

  .welcome-span {
    display: block;
    color: lightgray;
    margin-bottom: 0.5rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 1rem;
    /* font-weight: bold; */
  }

  .hero-title {
    font-weight: 500;
    text-transform: none;
    font-size: 1.25rem;
    letter-spacing: 0px;
    text-transform: uppercase;
    font-family: "Cinzel", sans-serif !important;
    line-height: 1.25;
    color: whitesmoke;
    display: block;
    padding-bottom: 1rem;
  }
}

@media (min-width: 769px) {
  .culture-img {
    object-fit: cover !important;
    object-position: -10rem;
  }

  .vision-culture-content-container {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-gap: 2rem;
    gap: 2rem;
  }

  .vc-list-container {
    width: 100%;
    padding: 0 2rem;
  }

  .vc-title {
    text-align: center;
    color: var(--primary-gold);
    font-family: var(--heading-text) !important;
    margin-right: 1rem;
    margin-bottom: 0;
  }

  .vc-list {
    height: 100%;
    width: 100%;
    margin: 0 auto;

    display: grid;

    list-style-type: disc;
  }

  .vc-list-1 {
    width: 70%;
  }

  .vc-list-2 {
    width: 80%;
  }

  .vc-list > li {
    /* flex: 1; */
    color: whitesmoke;
    margin: 1rem 0;
  }

  .vc-list-container-1 {
    display: grid;
    align-content: center;
    height: 100%;
  }
}

@media (max-width: 768px) {
  .vision-culture-content-container {
    display: grid;
    grid-template-columns: 1fr;
    /* gap: 1rem; */
    padding: 0 2rem;
  }

  .vc-title {
    color: var(--primary-gold);
    font-family: var(--heading-text) !important;
  }

  .vc-list {
    height: 100%;
    padding: 0 1rem;
    /* display: flex;
    flex-direction: column; */
    /* display: grid; */
    /* align-items: start; */
    /* grid-template-rows: 1fr 1fr 1fr 1fr 1fr; */
    /* flex: 1; */
  }

  .vc-list > li {
    /* flex: 1; */
    color: whitesmoke;
    margin: 0.5rem 0;
  }
}

@media (min-width: 769px) {
  .story-img {
    object-fit: cover !important;
    object-position: right;
  }
}

.media-video {
  width: 100%;
}

.video-card {
  border-bottom: 1px solid var(--primary-gold);
  flex-basis: 500px;
  flex-shrink: 0;
  /* width: 500px; */
  scroll-snap-align: start;
  flex: none;
}

.media-container {
  display: flex;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  width: 100%;
  flex-flow: row nowrap;
  /* grid-template-columns: 1fr ; */
  grid-gap: 3rem;
  gap: 3rem;
}

.video-date {
  text-decoration: underline;
  margin-bottom: 1.5rem;
}

.video-title {
  margin-bottom: 0.5rem;
  margin-top: 0rem;
  font-size: 1.5rem !important;
  color: var(--primary-gold) !important;
}

.video-description {
  margin-top: 0.25rem;
}

.video-subtitle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;
}

/* .highlighted-video {
  box-shadow: 0px 0px 20px 5px rgba(0, 0, 0, 0.308);
} */

@media (min-width: 851px) {
  .video-card {
    margin: 0 0.5rem;
  }
}

@media (max-width: 850px) {
  .media-container {
    display: grid;
    grid-template-columns: 1fr;
    padding: 1rem 2rem;
    grid-gap: 2rem;
    gap: 2rem;
  }

  .video-card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }

  .video-subtitle {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 1rem;
    font-size: 1rem;
  }

  .highlighted-video {
    -webkit-animation-name: flash;
            animation-name: flash;
    -webkit-animation-duration: 2s;
            animation-duration: 2s;
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
  }

  @-webkit-keyframes flash {
    from {
      background-color: lightgray;
    }
    to {
      background-color: transparent;
    }
  }

  @keyframes flash {
    from {
      background-color: lightgray;
    }
    to {
      background-color: transparent;
    }
  }
}

