@media (min-width: 769px) {
  .image-card-grid {
    display: grid;
    padding: 1rem 0;
    margin-top: 1rem;
    gap: 1rem;
    /* grid-template-columns: 600px 600px; */
    grid-template-columns: 1fr;
    grid-template-columns: 1fr 1fr;
    /* grid-template-columns: repeat(auto-fill, minmax(1fr, 1fr)); */
    /* display: flex;
        flex-direction: row; */
    overflow-x: scroll !important;
    justify-items: center;
    justify-content: center;
    /* width: 125%; */
    position: relative;
  }

  .image-card-container {
    background-color: whitesmoke;

    height: 100%;
    width: 100%;

    display: flex !important;
    flex-direction: column;
    justify-content: flex-start !important;
    flex-basis: content;
    align-items: flex-start;
  }

  .image-card-img-cont {
    width: 100%;
  }

  .image-card-img {
    /* height: 100%; */
    flex: 1;
    /* height: 200px; */
    height: 10rem;
    width: 100%;
    object-fit: cover;
  }

  .image-card-title {
    text-transform: uppercase;
    letter-spacing: 1px;
    color: var(--primary-blue);
    font-size: 1rem !important;
    font-weight: 500;
    margin: 0;
    /* padding-top: 1rem; */
    font-family: var(--heading-text) !important;
  }

  .image-card-main {
    /* background-color: whitesmoke; */
    margin: 0.5rem 1rem;
    border-bottom: 1px solid lightgray;
    flex: 2;
  }

  .image-card-text {
    color: rgba(0, 0, 0, 0.596) !important;
    font-size: 1rem !important;
  }

  .image-card-link-cont {
    /* grid-column: 2/3; */
    /* padding: 0rem 1rem 1rem 1rem; */
    padding-bottom: 1rem;
    padding-right: 1rem;
    padding-left: 1rem;
    max-height: 100px;
  }

  .image-card-link {
    text-transform: uppercase;
    letter-spacing: 1px;
    color: var(--primary-blue);
    font-size: 0.85rem !important;
    font-size: 1rem !important;

    /* width: 100vw; */
    font-family: var(--heading-text) !important;
    text-decoration: none;

    /* margin-left: 1rem; */
  }

  .image-card-link:hover {
    color: var(--primary-gold);
  }

  .fp-image-card-grid {
    width: 100%;
    grid-template-columns: 1fr !important;
    justify-items: center !important;
  }

  .fp-img-card-container {
    /* width: 75%; */
  }
}
@media (max-width: 768px) {
  .image-card-container {
    display: grid;
    background-color: whitesmoke;
    grid-template-columns: 1fr;
    padding-bottom: 1rem;
    width: 100%;
    gap: 0.5rem;
    /* margin-bottom: 2rem; */
  }

  .image-card-img {
    height: 200px;
    width: 100%;
    object-fit: cover;
  }

  .image-card-title {
    text-transform: uppercase;
    letter-spacing: 1px;
    color: var(--primary-blue);
    font-size: 1rem !important;
    font-weight: 500;
    margin: 0;
    font-family: var(--heading-text) !important;
  }

  .image-card-main {
    /* background-color: whitesmoke; */
    margin: 0.5rem 1rem;
    border-bottom: 1px solid lightgray;
  }

  .image-card-text {
    color: rgba(0, 0, 0, 0.596) !important;
    font-size: 1rem !important;
  }

  .image-card-link-cont {
    /* grid-column: 2/3; */
    /* padding: 0rem 1rem 1rem 1rem; */
    padding-bottom: 0rem;
    padding-left: 1rem;
    padding-right: 1rem;
    max-height: 100px;
  }

  .image-card-link {
    text-transform: uppercase;
    letter-spacing: 1px;
    color: var(--primary-blue);
    font-size: 0.75rem !important;

    /* width: 100vw; */
    font-family: var(--heading-text) !important;
    text-decoration: none;
    font-weight: 500;
    text-decoration: underline;
  }
}
